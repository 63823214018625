import axios from 'axios';
import { serverConfig } from './apiConfig';
export class Api {
  BASE_URL;
  defaultHeader;

  constructor() {
    this.BASE_URL = serverConfig.dev.url;
    //this.BASE_URL = serverConfig.production.url;

    this.defaultHeader = {
      'Content-Type': 'application/json',
    };
  }

  async Fetch(data: { method: any; url: any; headers?: any; body?: any; }) {
    let axiosResponse;
    let fetchUrl = null;
    //console.log('data.url : ', data.url);
    if (data.url === undefined) {
      fetchUrl = this.BASE_URL;
    } else {
      fetchUrl = this.BASE_URL + data.url;
    }
    //console.log('fetchUrl : ', fetchUrl);
    // console.log('this.defaultHeader : ', this.defaultHeader);
    

    const headers = data.headers ? data.headers : this.defaultHeader
    // console.log('headers ===> : ', headers );
    // console.log('this.defaultHeader ===> : ', this.defaultHeader );
    // console.log('data.body  : ', data.body );
    try {
      axiosResponse = await axios.request({
        url: fetchUrl,
        method: data.method,
        data: data.body,
        headers: headers
        // withCredentials: true,
      });
      
      
    } catch (error) {
      axiosResponse = error;
      console.log('error api : ', axiosResponse);
    }
    //console.log('api resposnse : ', axiosResponse);
    return axiosResponse;
  }
}
