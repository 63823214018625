import React, { useState, useEffect } from 'react';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { loginStart, loginSuccess } from '../redux/auth/actions';
import { setUser,  } from '../redux/user/actions';
import { setImageProfile  } from '../redux/usuarios/actions';

import { useNavigate } from 'react-router-dom'
import logo_centro from './../assets/images/selo_transp.png'
import { motion } from "framer-motion";
import { RootState } from '../redux/store';
import {client, AUTH_USER, getUsers, whoami, filterCredit, filterUser} from '../graphqlClient/graphql'
import {useMutation } from '@apollo/client';
import { serverConfig } from './../api/apiConfig';
import Notifications from './../components/notifications/Notifications';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: 'transparent',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    backgroundColor: 'white',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    marginTop:'50px'
  },
  textField: {
    margin: theme.spacing(1),
    width: '300px',
    color: 'green'
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  error: {
    color: 'red',
    marginTop: theme.spacing(1),
  },
  check: {
    color: '#05f228',
    marginTop: theme.spacing(1),
  },
  marca:{
    position: 'absolute',
    top: '73px',
    width:'220px',
    zIndex:0,
  },
  box: {
    position: 'absolute',
    top: '110px',
    width: '250px',
    height: '180px',
    backgroundColor: 'white',
    zIndex:1,
    
  },
  container_anim: {
    position: 'absolute',
    top: '110px',
    width: '270px',
    height: '220px',
    backgroundColor: 'forestgreen',
  
    
  }
}));
type PropsFromRedux = ConnectedProps<typeof connector>;
const LoginPage : React.FC<PropsFromRedux> = ({ isLogged, user, errorMessage,setUser,setImageProfile }) => {
  const classes = useStyles();
  const [AuthUserMutation, { data }] = useMutation(AUTH_USER);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error1, setError1] = useState('');
  const [animation, setAnimation] = useState(0);
  const [motionStart, setMotionStart] = useState(0);

  useEffect(()=>{
    setTimeout(()=>setMotionStart(1),1400)
  },[])
 
  useEffect(()=>{
    if(isLogged){
      //dispatch(getUser())
      //navigate('/dashboard');
    }
  },[isLogged])


  const getUsersAsync = async () => {
    
    const objToSend = {
      //startDate,
      //endDate,
      //empresa:empresa
      role: 'scout',
      //usuario : selectedUser?._id || '',

    }
    //setIsLoadingCompaniesByFilter(true)
    console.log('objToSend', objToSend)

    const result = await filterUser(objToSend)
    console.log('filter users result ==>>', result)

    const users = result
    console.log(users)
    if (users.length) {
      
    }
    

  }

 
  
  const handleLogin = async () => {
    if (email === '' || password === '') {
      setError1('Please, fill all fields');
      return;
    }
    try{
      
      const userToken = await AuthUserMutation({
        variables: {
          email: email,
          password: password
        },
        onCompleted: ({ login }) => {
          setError1('LogIn success')
          localStorage.setItem('token', login.token);
          console.log('login == > ',login);
        }
      });
      
      const token = userToken?.data?.login?.token
      console.log('userToken==>>', token)
      if (token) {
        try{
          const user:any = await whoami()
          console.log('whoami',user)
          setUser(user)
          if(user.image){
            const imagePath = serverConfig.dev.url + 'images/' + user.image;
           
            setImageProfile(imagePath)
            console.log('configurou o image profile')
          }



          

          dispatch(loginSuccess(user)); // Altera o estado isLogged para true
        } catch(err) {
          console.warn("Erro no WhoAmI", err)
        } 
        
      }
    } catch(err:any){
      console.log('err == >>>', err.message)
      /* for(var i in err){
        console.log(i + ':'+err[i])
      } */
      setError1(err.message)
    }
    
    // Chamada à ação de login do Redux
   /*  dispatch(loginStart({ email, password })) .then((val:any) => {
      console.log('val',val)
      // Lógica adicional após o login bem-sucedido, se necessário
      //dispatch(loginSuccess()); // Altera o estado isLogged para true
      
    })
    .catch((error1) => {
      console.log(error1)
      // Lógica para lidar com erros no login, se necessário
    });; */
    
    // Lógica adicional de autenticação, como chamar a API de login
    // e lidar com a resposta para despachar a ação `loggedSuccess`
  };

  return (
    <div className={classes.root}>
        {
          motionStart ? (
            <motion.img
              src={logo_centro}
              alt="wescout"
              className={classes.marca}
              initial={{ opacity: 0, y: 0 }} // Configuração inicial da animação
              animate={{ opacity: 1, y: 0 }} // Configuração de animação quando a imagem é renderizada
              transition={{ duration: 1}} // Duração da animação em segundos
              onAnimationComplete={definition => {
                setAnimation(1)
              }}
            />

          ) : null
        }
        {/* {
          motionStart ? (
            <motion.div
              //src={logo_centro}
              
              className={classes.box}
              initial={{  x: -20, scaleX:1 }} // Configuração inicial da animação
              animate={{  opacity:.5, x:180, scaleX:0 }} // Configuração de animação quando a imagem é renderizada
              transition={{ duration: 1.2 }} // Duração da animação em segundos
              onAnimationComplete={definition => {
                setAnimation(1)
              }}
            />
          ) : null
        } */}

      
      
      



    
    {
      animation ? (
        <form className={classes.form}>
          
        <TextField
          className={classes.textField}
          label="e-mail"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          className={classes.textField}
          label="password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {error1 && <Typography className={!error1.search('success')? classes.error : classes.check}> {error1} </Typography>}

        <Button className={classes.button} variant="contained" onClick={handleLogin}>
          log in
        </Button>
        <Button onClick={() => navigate('/signup/1234')}>sign up</Button>
        
      </form>
        
        
        ) : null
        }
        
  

      
    </div>
  );
};


const mapStateToProps = (state: RootState) => ({
  isLogged : state.auth.isLogged,
  errorMessage : state.auth.errorMessage,
  user: state.user.user, // Ajuste aqui para a propriedade correta do estado do usuário
});

const mapDispatchToProps = {
  setUser,
  setImageProfile
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(LoginPage);