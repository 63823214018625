import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, Collapse, Tooltip, Zoom } from '@material-ui/core';
import { Button, Container, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal } from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CardMedia from '@mui/material/CardMedia';

import CloudUpload from '@material-ui/icons/CloudUpload';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import QrCode from '@mui/icons-material/QrCode';
import ViewHeadline from '@material-ui/icons/ViewHeadline';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { connect, ConnectedProps } from 'react-redux';
import store from '../redux/store';
import { getUsuarios, deleteUsuario, createUsuario, updateUsuario } from '../redux/usuarios/actions';
import { getAllInventoryStart, consultProductStart, getInventoriesByCategory, getInventoriesByFilter } from '../redux/produtos/actions';

import { fetchEmpresas, } from '../redux/empresas/actions';
import { getImagesByReferencia, } from '../redux/referencias/actions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/Help';

import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';


import { InputAdornment } from '@material-ui/core';
import { Email, Phone, Business, ExpandLess, ViewList } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { Autocomplete, Card, CardActions, CardContent, CardHeader, Divider, LinearProgress, ListItemButton, ListSubheader, Stack } from '@mui/material';
import moment from 'moment'
import { VictoryBar, VictoryPie } from 'victory';
import { client, AUTH_USER, getUsers, whoami, UPDATE_USER, CREATE_USER, filterUser, getCategories, findProfileByUser } from '../graphqlClient/graphql'

import Box from '@mui/material/Box';
import {
  useGridApiRef,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { serverConfig } from '../api/apiConfig';

// Core viewer
import { Viewer, Worker } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between!important',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  addButton: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignContent: 'center',
    flexWrap: 'nowrap',
    //alignItems: 'flex-start',
    // justifyContent: 'center',
    textAlign: 'center',
    border: '1px solid green',
    width: '80%'
  },
  listContainer: {
    //maxWidth:'80%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    justifyContent: 'center',
    alignItems: 'flex-start',

    maxHeight: 'calc(100vh - 100px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'green',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  irButton: {
    width: 30,
    margin: 10
  }
}));

const chartConfig = {
  backgroundColor: '#26872a',
  backgroundGradientFrom: '#43a047',
  backgroundGradientTo: '#66bb6a',
  color: (opacity = 1) => `#ffffff`,
  style: {
    borderRadius: 16
  }
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const workerUrl = new URL(
  'https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js',
  import.meta.url,
).toString();

type PropsFromRedux = ConnectedProps<typeof connector>;

const ScoutingPoolPage: React.FC<PropsFromRedux> = ({ usuario }) => {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const screenWidth: any = getWindowDimensions().width
  const screenHeight: any = getWindowDimensions().height
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedUsuario, setSelectedUsuario] = React.useState('');

  const [editUsuarioData, setEditUsuarioData] = React.useState<any>({});
  const [newUsuarioPermissoes, setNewUsuarioPermissoes] = React.useState<any>({});
  const [editUsuarioModalOpen, setEditUsuarioModalOpen] = React.useState(false);
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [category, setCategory] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState<any>({});
  const [categoriesToShow, setCategoriesToShow] = React.useState([{ nome: 'Todas', _id: 0 }]);

  const [total, setTotal] = React.useState<any>(0);


  const [dataChart, setDataChart] = React.useState<any>([]);
  const [visible, setVisible] = React.useState(false);
  const [progress, setProgress] = React.useState(10);
  const [limit, setLimit] = React.useState(0);
  const permissoesList = ['administrador', 'gerente', 'basico']
  const [rows, setRows] = React.useState<any>([]);
  const [users, setUsers] = React.useState<any>([]);
  const [categories, setCategories] = React.useState<any>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [selectedCategory, setSelectedCategory] = React.useState<any>({ key: 0, value: 'Filtro por unidade' });
  const [selectedUser, setSelectedUser] = React.useState<any>({ key: 0, value: 'Filtro por operador' });
  const [selectedProduto, setSelectedProduto] = React.useState<any>({ key: 0, value: 'Filtro por produto' });
  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  const [expanded, setExpanded] = React.useState('');
  const [openPdf, setOpenPdf] = React.useState(false);
  const [selectedScout, setSelectedScout] = React.useState<any>({});
  const [scoutInfo, setScoutInfo] = React.useState<any>(false);

  const handleExpandClick = (id: string) => {
    const newValue = expanded == id ? '' : id
    setExpanded(newValue);
  };
  const apiRef = useGridApiRef();

  const getUsersAsync = async () => {
    setLoading(true)
    const objToSend = {
      //startDate,
      //endDate,
      //empresa:empresa
      role: 'scout',
      //usuario : selectedUser?._id || '',

    }
    //setIsLoadingCompaniesByFilter(true)
    console.log('objToSend', objToSend)

    const result = await filterUser(objToSend)
    console.log('filter users result ==>>', result)

    const users = result
    console.log(users)
    if (users.length) {
      setUsers(users)
    }
    setLoading(false)

  }
  const getCategoriesAsync = async () => {

    const result: any = await getCategories()
    console.log('categories ===>', result)

    if (result?.length) {
      setCategories(result)
    }
    return result;
  }


  useEffect(() => {
    changeFilter()

  }, [selectedUser, selectedCategory, selectedProduto, startDate, endDate]);


  useEffect(() => {
    getUsersAsync()
    getCategoriesAsync()
  }, []);
  let timer: any
  useEffect(() => {
    console.log('limit useEffect', limit)
    setProgress(limit)
    //if(limit == 0){
    //clearInterval(timer)
    return
    //}

    /* timer = setInterval(() => {
      console.log('progress',progress)
      console.log('limit',limit)
       
        setProgress((prevProgress) => 
        {
          console.log('prevProgress',prevProgress)
          const inc = limit
          if(prevProgress>=limit || inc>=limit){
            console.log('limpou',timer)
            clearInterval(timer);
            timer = 0
          }
          
          return(inc >= limit ? limit : inc)
        });
    }, 100); */

    //return clearInterval(timer)
  }, [limit]);





  /* useEffect(() => {
      if(inventoriesList.length){
        
        apiRef.current.setColumnVisibility('_id', false);
      }
  }, [inventoriesList]); */



  const handleDeleteClick = (UsuarioId: string) => {
    console.log('UsuarioId', UsuarioId)
    setSelectedUsuario(UsuarioId);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmation = () => {
    console.log('selectedUsuario', selectedUsuario)
    deleteUsuario(selectedUsuario);
    setDeleteConfirmationOpen(false);
  };


  const makePermissoes = () => {
    if (newUsuarioPermissoes == 'administrador') {
      return { admin: true }
    } else if (newUsuarioPermissoes == 'basico') {
      return { basic: true }
    } else if (newUsuarioPermissoes == 'gerente') {
      return { manager: true }
    } else {
      return {}
    }
  }






  const handlerSelectedItem = (item: any) => {
    console.log(item, item)
    setSelectedItem(item)



  }

  const getCategoriaNome = (id: any) => {
    const nome = categories.filter((item: any) => id == item._id)
    return nome.length ? nome[0].nome : id

  }




  const clearData = (d: any) => {
    const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
    return dateTransform
  }




  interface Props {
    title: any
    subTitle: any
    itensList: []
    type: any
  }


  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"

          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }


  const handlerCloseModal = () => {
    setVisible(false)
    setLimit(0)
    setProgress(0)
    clearInterval(timer);
    setSelectedScout({})
    setScoutInfo(false)
    setLoading(false)
  }

  const compare = (a: any, b: any) => {
    if (a.createdAt > b.createdAt)
      return -1
    else if (a.createdAt < b.createdAt)
      return 1
    else
      return 0

  }


  const changeCategoria = (cat: any) => {

    console.log('categoria id: ', cat)

    if (cat == 0) {
      getAllInventoryStart()
    } else {
      //get(cat)
    }

  }


  //init DataGrid dependencies

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row: any) => row._id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row: any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row: any) => row._id !== id));
    }
  };

  const processRowUpdate: any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow', newRow)
    //setEditProdutoData(newRow)
    setRows(rows.map((row: any) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Data',
      type: 'Date',
      width: 120,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => clearData(params.row.createdAt)
    },
    {
      field: 'categoria',
      headerName: 'Unidade',
      width: 120,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => getCategoriaNome(params.row.categoria)
    },
    {
      field: 'usuario',
      headerName: 'Operador',
      width: 120,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.usuario?.nome
    },

    {
      field: 'quantidade',
      headerName: 'Quantidade',
      type: 'number',
      width: 90,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.conteudo.productsByCategory.length
    },
    {
      field: 'resultado',
      headerName: 'Resultado',
      type: 'number',
      width: 90,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => (Math.round((params.row.conteudo.progress || 1) * 100)) + '%'
    },

    {
      field: 'identificados',
      headerName: 'Identificados',
      type: 'number',
      width: 90,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => ((params.row.conteudo.tagsEquals.length || 1))
    },
    {
      field: 'tagsLost',
      headerName: 'Nâo encontrados',
      type: 'number',
      width: 90,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => ((params.row.conteudo.tagsLost.length || 0))
    },
    {
      field: 'notfound',
      headerName: 'Externos',
      type: 'number',
      width: 80,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => ((params.row.conteudo.tagsNotFound.length || 0))
    },
    {
      field: 'lidos',
      headerName: 'Lidos',
      type: 'number',
      width: 80,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => ((params.row.conteudo.tagsFound.length || 1))
    },
    /* {
      field: 'identificacao',
      headerName: 'código',
      description: 'código do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].codigo || ''}  `,
    }, 
    {
      field: 'updatedAt',
      headerName: 'tipo identificacao',
      description: 'tipo de identificacao',
      sortable: false,
      width: 75,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].tipo || ''} `,
    }, */
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        //const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const inventory = categories.filter((item: any) => item._id == id)[0]
        return [

          <GridActionsCellItem
            icon={<Tooltip TransitionComponent={Zoom} title="Relatório" arrow>
              <IconButton className='iconDetail' color="primary" aria-label="Relatório" >
                <ViewHeadline />
              </IconButton>
            </Tooltip>}
            label="Relatório"
            className="textPrimary"
            onClick={() => handlerSelectedItem(inventory)}
            color="inherit"
          />,

        ];
      },
    },


  ];

  const hiddenFields = ['_id', 'id', 'ID'];

  const getTogglableColumns = (columns: GridColDef[]) => {
    console.log('getTogglableColumns', columns)
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };

  interface AutocompleteOption {
    categoria: string;
  }

  const changeFilter = () => {
    setLoading(true)

    const objToSend = {
      startDate,
      endDate,

      //categoria:selectedCategory?._id || '',

    }
    console.log('objToSend', objToSend)
    //getInventoriesByFilter(objToSend)
    setLoading(false)

  }

  const getProfileByUserAsync = async (userId: any) => {
    try {
      setLoading(true)
      console.log('userId', userId)
      const profile: any = await findProfileByUser(userId);
      console.log('profile ==>>', profile)
      setLoading(false)
      setEditUsuarioData(profile)
    }
    catch (error) {
      console.log("Erro no getUser >>>>>", error)
    }
  }

  const handleScoutInfo = async (user: any) => {
    console.log('user', user)
    await getProfileByUserAsync(user._id)
    //setSelectedRecommendation(recom)
    setSelectedScout(user)
    setScoutInfo(true)
    //setRequestPanel(true)
    //handleAddReferral(recom)
    //setRequestPanel(true)

  }

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
        <div className={classes.content}>
          {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}
          <Typography variant="h4" className={'title'}>
            Scouting Pool
          </Typography>

          {loading ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : null}

          {/* <FormControl fullWidth>
              <InputLabel className={'text'} htmlFor="tipo-select">Itens por Unidade</InputLabel>
              <Select
                className={classes.select}
                value={selectedCategoria}
                onChange={(e:any) => {

                  setSelectedCategoria( e.target.value )
                  changeCategoria( e.target.value)
                  
                }}
                inputProps={{
                  name: 'categoria',
                  id: 'tipo-select',
                }}
              >
                {categoriasToShow.map((cat: { _id: any; nome: any; }) => (
                  <MenuItem className={'text'} key={cat._id} value={cat._id}>
                    {cat.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

          <Box sx={{ width: '100%', height: 110, }} style={{ alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>
            <Stack borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
              <Box sx={{ width: '100%' }} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', height: 30, alignContent: 'center', justifyContent: 'space-around', alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>
                
                <Box >
                <Autocomplete
                  getOptionLabel={(cat: any) => getCategoriaNome(cat.name)}
                  //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                  onChange={(event, newValue) => {
                    console.log('newValue ==> ', newValue)
                    setSelectedCategory(newValue)
                    if (!newValue) {

                      //getAllInventoryStart()
                      return
                    }

                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={categories}
                  sx={{ width: 300 }}
                  renderInput={(params: any) => <TextField {...params} label="Category" />}
                />
                <Tooltip TransitionComponent={Zoom} title="Select the category of your interest" arrow>
                  <IconButton aria-label="settings">
                        <HelpIcon />
                      </IconButton>
                </Tooltip>
                
                </Box>

                {/* <Autocomplete
                getOptionLabel={(usu:any) => usu.nome }
                onChange={(event, newValue) => {
                    console.log(newValue)
                    setSelectedUser(newValue)        
                    //changeFilter()
                    
                }}
                disablePortal
                id="combo-box-usuario"
                options={usuarios}
                sx={{ width: 300 }}
                renderInput={(params:any) => <TextField {...params} label="Expertise" />}
              /> */}
                {/* <Autocomplete
                getOptionLabel={(prod:any) => getProdutoNome(prod.nome) }
                onChange={(event, newValue) => {
                    console.log(newValue)
                    setSelectedProduto(newValue)
                  
                }}
                disablePortal
                id="combo-box-produtos"
                options={produtos}
                sx={{ width: 300 }}
                renderInput={(params:any) => <TextField {...params} label="Country" />}
              /> */}
              </Box>
              {/* <Box sx={{  width: '100%' }} style={{display: 'flex',flexDirection: 'row',flexWrap: 'nowrap',height:70, alignContent: 'center', justifyContent: 'center', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker label="Initial Date" 
                          format="DD/MM/YYYY"
                          onChange={(value:any)=>{
                            if(!value) return
                            console.log('inicial',value.$d)
                            setStartDate(value.$d)
                          }} 
                        />
                  <DatePicker label="End Date" 
                          format="DD/MM/YYYY"
                          onChange={(value:any)=>{
                            if(!value) return
                            const valueDate = new Date(value.$d)
                            valueDate.setHours(23)
                            valueDate.setMinutes(59)
                            valueDate.setSeconds(59)
                            //console.log('day', day)
                            console.log('final', valueDate)
                            setEndDate(valueDate)
                          }}
                        
                        />
                </DemoContainer>
              </LocalizationProvider>
              <Button className='{classes.irButton}' onClick={() => changeFilter()}>Ir</Button>
            </Box> */}

            </Stack>


          </Box>
          {/*   {rows.length ? (
            <Box sx={{  width: '100%' }}>
              
            <DataGrid
              apiRef={apiRef}
              loading={isLoadingInventory}
              getRowId={(row: { _id: any; }) => row._id}
              //checkboxSelection
          disableRowSelectionOnClick
          
          onClipboardCopy={(copiedString) => console.log(copiedString)}
          unstable_ignoreValueFormatterDuringExport
              autoHeight={true}
              rows={rows}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'desc' }],
                },
                
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              //checkboxSelection
              
              //editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              // slots={{
              //   toolbar: EditToolbar,
              // }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { setRows, setRowModesModel,csvOptions:{
                  fileName: 'inventariosDataBase_',
                  delimiter: ';',
                  utf8WithBom: true,
                },
                columns: {
                  columnVisibilityModel:{ID: false,},
                },
                columnsPanel: {
                  getTogglableColumns,
                },
                printOptions: {hideToolbar:true, disableToolbarButton: false }},
              }}

              
       
              //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        
        
            />
              
          </Box>
          ):(<div style={{margin:15}}>
            not found
          </div>)} */}


          {users.length ? (
            <div className={classes.listContainer}>

              {users.map((user: any, index: any) => (
                <Card sx={{ maxWidth: 345 }}>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: '#0df228' }} aria-label="recipe">
                        {user.name.substring(0, 2)}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title={user.name}
                    subheader={clearData(user.createdAt)}
                  />
                  <CardMedia
                    component="img"
                    height="194"
                    image={user.image ? serverConfig.dev.url + 'images/' + user.image : "https://robohash.org/" + user.name}
                    alt="User image"
                  />
                  <CardContent>
                    <Typography variant="body2" color="primary">
                      <Button className='{classes.irButton}' onClick={() => {
                        handleScoutInfo(user)
                      }}>scout info</Button>
                    </Typography>
                  </CardContent>
                  <CardActions disableSpacing>

                    <IconButton aria-label="add to favorites">
                      <FavoriteIcon />
                    </IconButton>
                    {/* <IconButton aria-label="share">
                      <ShareIcon />
                    </IconButton> */}
                    <ExpandMore
                      expand={expanded == user._id ? true : false}
                      onClick={() => { handleExpandClick(user._id) }}
                      aria-expanded={expanded == user._id}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expanded == user._id} timeout="auto" unmountOnExit>
                    <CardContent>


                      <Typography paragraph>
                        Invite this scout to be referred
                      </Typography>
                      <Typography paragraph>
                      <Button className='{classes.irButton}' onClick={() => {
                        handleScoutInfo(user)
                      }}>Invite</Button>
                      </Typography>
                      
                    </CardContent>
                  </Collapse>
                </Card>
              ))}

            </div>

          ) : null}
          <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
        </div>
      </div>

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancelar</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Modal className={classes.modalContent} open={scoutInfo} onClose={() => handlerCloseModal()}   >

        <div style={{ margin: 0, backgroundColor: 'white', width: screenWidth }} className={classes.modalContent}>

          <Button className='backbutton' onClick={() => handlerCloseModal()}>Go Back</Button>

          <div className={classes.listContainer}>
            <div style={{ margin: 15, }}>
              <div >
                <Stack borderRadius={15} spacing={2} direction="row" justifyContent={'center'} alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
                  <div style={{ fontSize: 18, margin: 15 }}>Professional Title: {editUsuarioData.professionalTitle}</div>

                  <div style={{ fontSize: 18, margin: 15 }}>Years Of Experience: {editUsuarioData.yearsOfExperience}</div>


                </Stack>

                <div style={{ fontSize: 18, margin: 15 }}>

                  <TextField
                    style={{ marginBottom: 5 }}
                    variant="outlined"
                    hiddenLabel
                    label="bio"
                    multiline
                    maxRows={4}
                    value={editUsuarioData.bio || ""}
                    onChange={(e) => setEditUsuarioData({ ...editUsuarioData, bio: e.target.value })}
                  />
                </div>
                <Divider />
                <CircularProgressWithLabel style={{ margin: 15 }} value={80} />
                <Divider />
                {/* <div style={{ fontSize: 18, margin: 15 }}>
                  <Link href={`${selectedRecommendation.link}`} target="_blank" underline="hover">
                    {'Job link'}
                  </Link>

                </div> */}
                {/* <div style={{ fontSize: 18, margin: 15 }}>Location: {selectedRecommendation.location}</div> */}
                {/* <div style={{}}>Itens lidos: {tagsFound.length} </div> */}
                {limit ? (<CircularProgressWithLabel style={{ margin: 15 }} value={progress} />) : null}
                {/* <div style={{ alignSelf: 'center', marginTop: 15 }}>
                  <PieChart
                    series={[
                      {
                        data: dataChart
                      },
                    ]}
                    width={400}
                    height={200}
                  />
                </div> */}

                {/* <VictoryPie
                      width={450}
                      height={380}
                      data={dataChart}
                      labels={({ datum }) => `${datum.y}: ${datum.label}`}
                      colorScale={["green", "red", "gold" ]}
                      animate={{
                        duration: 2000
                      }}
                      style={{
                        data: {
                          fillOpacity: 0.9, stroke: "#0af253", strokeWidth: 2, 
                        },
                        labels: {
                          fontSize: 14,  marginLeft:-10
                        }
                      }}
                    /> */}

              </div>
            </div>




          </div>

          {/* <div>
                //button to trigger printing of target component 
                <ReactToPrint
                  trigger={() => <Button>Print this out!</Button>}
                  content={() => componentRef}
                />

                
                <ComponentToPrint ref={(el) => (componentRef = el)} />
              </div> */}

        </div>
      </Modal>


      <Modal className={classes.modalContent} open={openPdf} onClose={() => setOpenPdf(false)}   >
        <>
          <Worker workerUrl={workerUrl}>
            <Viewer
              fileUrl={serverConfig.dev.url + 'images/pdf/' + editUsuarioData.pdf}
              plugins={[
                // Register plugins
                defaultLayoutPluginInstance,
              ]}

            />
          </Worker>
        </>
      </Modal>






      {/* <Modal open={editUsuarioModalOpen} onClose={() => setEditUsuarioModalOpen(false)}>
  <div>
    <input
      type="text"
      value={editUsuarioData.nome}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, nome: e.target.value })}
    />
    <input
      type="text"
      value={editUsuarioData.email}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, email: e.target.value })}
    />
    <input
      type="text"
      value={editUsuarioData.telefone}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, telefone: e.target.value })}
    />
    <input
      type="text"
      value={editUsuarioData.cnpj}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdateUsuario}>Atualizar</button>
  </div>
</Modal> */}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  usuario: state.user.user,
});

const mapDispatchToProps = {
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ScoutingPoolPage);
