import React, { ChangeEvent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {  Button, Container, FormControl, InputAdornment, InputLabel, Select, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Business, CloudUpload, Email, Phone } from '@material-ui/icons';
import { ConnectedProps, connect } from 'react-redux';

import { fetchEmpresas, fetchEmpresaById, updateEmpresa, getImagesByEmpresa, setImageEmpresaProfile } from './../redux/empresas/actions';
import { deleteUsuario } from './../redux/usuarios/actions';
import { useNavigate } from 'react-router-dom';
import {serverConfig} from './../api/apiConfig';
import Avatar from '@mui/material/Avatar';
import { Box, Divider, LinearProgress, MenuItem } from '@mui/material';


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {
    
    textAlign: 'center',
  },
  form_content: {
    marginTop:20,
      display: 'flex',
      flexDirection: 'column',
      aligContent: 'center',
      justifyContent: 'space-around',
      alignItems: 'stretch',
  
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  button:{
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  }
}));
type PropsFromRedux = ConnectedProps<typeof connector>;
const EmpresaPerfilPage: React.FC<PropsFromRedux>  = ({usuario, empresa,isLoadingEmpresa, fetchEmpresaById,isLoadingUsuario, imagesByEmpresa, getImagesByEmpresa,setImageEmpresaProfile, updateEmpresa}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  
  const [editEmpresaData, setEditEmpresaData] = React.useState<any>({});

  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [newUsuarioConfirmPassword, setNewUsuarioConfirmPassword] = React.useState('');

  const [loading, setLoading] = React.useState(false);

  const permissoesList = ['administrador','gerente','basico']
  const [contador, setContador] = React.useState(0);
  const [file, setFile] = React.useState<File>();
  const [image, setImage] = React.useState<any>('');
  const [update, setUpdate] = React.useState<any>(false);
  
  useEffect(() => {
    if(isLoadingEmpresa){
      setLoading(true)
    }
    if(loading && !isLoadingEmpresa){
      fetchEmpresaById(usuario.empresa)
      setLoading(false)
    }
    console.log('isLoadingEmpresa ==> ',isLoadingEmpresa)
    
  }, [isLoadingEmpresa]);
 
  useEffect(() => {
    console.log("imagesByEmpresa useEffect", imagesByEmpresa)
    const imagesList:any = [];
    let cont = 0
    imagesByEmpresa.map((item:any, index:any)=>{
      cont = (cont < item.contador ? item.contador : cont)
      const slide = {
        key: index,
        title: 'Usuario',
        text: '',
        image:serverConfig.dev.url + 'imagens/imagem/'+item._id,
        backgroundColor: 'green',
        
      }
      imagesList.push(slide)
    })
    console.log('imagesList ==>>> ', imagesList)
    console.log('cont ==>>> ', cont)
    setContador(cont)
    if(imagesList.length){
      const image = imagesList[0].image
      setImage(image)
      setImageEmpresaProfile(image)
    }
    //setSlides(imagesList)
   
  },[imagesByEmpresa]);

 
  
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]))
    }
  };

  useEffect(()=>{
    console.log(empresa)
    setEditEmpresaData(empresa)
    getImagesByEmpresa(empresa._id)
  },[empresa])
  useEffect(()=>{
    let perm = 'basico'
    if(usuario.permissoes?.admin) {
      perm = 'administrador'
    }else if(usuario.permissoes?.manager){
      perm = 'gerente'
    }

    /* if(usuario?.empresa){
      
      fetchEmpresaById(usuario.empresa)
    } */

    console.log('usuario',usuario)
  }, [usuario])

  const handleUpdate = () => {
    setUpdate(true)
    console.log('editEmpresaData',editEmpresaData)
    const dataToSend = editEmpresaData
    
    if(file){
      console.log(file)
      dataToSend.file = file;
      dataToSend.contador = (contador+1);
    } 
    console.log('dataToSend',dataToSend)
    updateEmpresa(dataToSend)
    
    
  };

  const validateEmail = (email:any) => {
    return true
    // Implemente a lógica de validação do e-mail aqui
  };

  const validatePassword = (password: string | any[]) => {
    return password.length >= 6;
  };
  
  const validateConfirmPassword = (password: any, confirmPassword: any) => {
    return password === confirmPassword;
  };

  const validateCNPJ = (cnpj:any) => {
    // Remove caracteres não numéricos
    if(!cnpj) return false;
    cnpj = cnpj.replace(/[^\d]+/g, '');
    
    // Verifica se possui 14 dígitos
    if (cnpj.length !== 14) {
      return false;
    }
    
    // Verifica se todos os dígitos são iguais (ex: 00000000000000)
    if (/^(\d)\1+$/.test(cnpj)) {
      return false;
    }
    
    // Validação do primeiro dígito verificador
    let sum = 0;
    let factor = 5;
    for (let i = 0; i < 12; i++) {
      sum += parseInt(cnpj[i]) * factor;
      factor = factor === 2 ? 9 : factor - 1;
    }
    
    let digit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (parseInt(cnpj[12]) !== digit) {
      return false;
    }
    
    // Validação do segundo dígito verificador
    sum = 0;
    factor = 6;
    for (let i = 0; i < 13; i++) {
      sum += parseInt(cnpj[i]) * factor;
      factor = factor === 2 ? 9 : factor - 1;
    }
    
    digit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (parseInt(cnpj[13]) !== digit) {
      return false;
    }
    
    return true;
  };
  
  
  const maskCNPJ = (cnpj:any) => {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]+/g, '');
    
    // Aplica a máscara
    cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    
    return cnpj;
  };

  const validatePhone = (phone:any) => {
    // Remove caracteres não numéricos
    if(!phone) return false;
    phone = phone.replace(/[^\d]+/g, '');
    
    // Verifica se possui 11 dígitos (considerando o DDD)
    if (phone.length !== 11) {
      return false;
    }
    
    // Verifica se todos os dígitos são iguais (ex: 00000000000)
    if (/^(\d)\1+$/.test(phone)) {
      return false;
    }
    
    return true;
  };
  
  const maskPhone = (phone:any) => {
    // Remove caracteres não numéricos
    phone = phone.replace(/[^\d]+/g, '');
    
    // Aplica a máscara
    phone = phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    
    return phone;
  };

  return (
    <Container className={classes.container}>
      <div className={classes.content}>
      {loading ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : null}
      <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
        <Typography variant="h4" className={'title'}>
          Perfil da Empresa
        </Typography>
        {/* Adicione os componentes e funcionalidades específicas da página do usuario */}
        <div className={classes.form_content}>
          <Avatar sx={{ width: 112, height: 112 }} style={{alignSelf:'center'}} alt={usuario.nome} src={image||usuario.avatar} />
        <div>
        {/* {file&&file.name ? (<div>{file && `${file.name} - ${file.type}`}</div>):null} */}
        <div className="file-upload">
              {/* <img src={uploadImg} alt="upload" /> */}
              <CloudUpload />
              <h3> {file?.name || "Clique para escolher uma imagem"}</h3>
              <p>Tamanho máximo do arquivo 10mb</p>
              <input type="file" onChange={handleFileChange} />
            </div>

            
        </div>
          <TextField
            style={{marginBottom:10}}
            label="Nome da empresa"
            value={editEmpresaData.nome}
            onChange={(e) => setEditEmpresaData({ ...editEmpresaData, nome: e.target.value })}
          />
          <TextField
          style={{marginBottom:10}}
            label="E-mail do responsável"
            value={editEmpresaData.email}
            onChange={(e) => setEditEmpresaData({ ...editEmpresaData, email: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            error={!validateEmail(editEmpresaData.email)}
            helperText={!validateEmail(editEmpresaData.email) && 'E-mail inválido'}
          />

          <TextField
            style={{marginBottom:10}}
            label="Telefone"
            value={editEmpresaData.telefone}
            onChange={(e) => setEditEmpresaData({...editEmpresaData ,telefone : maskPhone(e.target.value)})}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              ),
            }}
            error={!validatePhone(editEmpresaData.telefone)}
            helperText={!validatePhone(editEmpresaData.telefone) && 'Telefone inválido'}
          />
          <TextField
            style={{marginBottom:10}}
            label="CNPJ"
            value={editEmpresaData.cnpj}
            onChange={(e) => setEditEmpresaData({...editEmpresaData, cnpj : maskCNPJ(e.target.value)})}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Business />
                </InputAdornment>
              ),
            }}
            error={!validateCNPJ(editEmpresaData.cnpj)}
            helperText={!validateCNPJ(editEmpresaData.cnpj) && 'CNPJ inválido'}
          />

          
            


            <Button className={classes.button} style={{margin:15,}} onClick={()=>{handleUpdate()}}>Gravar</Button>


          
          

            {/* <TextField
              label="Empresa"
              value={editEmpresaData.empresa}
              onChange={(e) => setNewUsuarioEmpresa(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Business />
                  </InputAdornment>
                ),
              }}
              error={editEmpresaData.empresa == ""}

              helperText={
                editEmpresaData.empresa=="" &&
                'Empresa inválida'
              }
            /> */}
        </div>
        {loading ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : null}
        <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
      </div>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  usuario: state.user.user, 
  
  isLoadingUsuario : state.usuarios.isLoadingUsuario,
  isLoadingCreateUsuario : state.usuarios.isLoadingCreateUsuario,
  empresas: state.empresas.empresas,
  empresa: state.empresas.empresa,
  isLoadingEmpresa: state.empresas.isLoadingEmpresa,
  imagesByEmpresa : state.empresas.imagesByEmpresa,
  imageEmpresaProfile : state.empresas.imageEmpresaProfile,
});

const mapDispatchToProps = {
  fetchEmpresas,
  deleteUsuario,
  updateEmpresa,
  getImagesByEmpresa,
  setImageEmpresaProfile,
  fetchEmpresaById
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EmpresaPerfilPage);


