// Importe as constantes de ação


import {

    GET_USER_START,
    GET_USER_SUCCESS,
    UPDATE_USER_START,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ROLE,
    UPDATE_USER_POSITION_SUCCESS,
    SET_USER_SUCCESS
  } from './actions';
  
  // Estado inicial
  const initialState = {
    user: {},
    isLogged : false,
    isLoadingUser : false,
    userPosition : {},
    empresa: {},
  }
  
  export default function empresaReducer(state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
      case GET_USER_SUCCESS:
        console.log('GET_USER_SUCCESS', action.payload)
        return { ...state, user: action.payload, isLoadingUser:false, isLogged: true };
      case SET_USER_SUCCESS:
        console.log('SET_USER_SUCCESS', action.payload)
        return { ...state, user: action.payload, isLoadingUser:false, isLogged: true };
      case GET_USER_START:
        return { ...state, isLoadingUser:false};
      
      case UPDATE_USER_START:
        console.log('UPDATE_USER_START')
        return { ...state, isLoadingUser:true};
      case UPDATE_USER_SUCCESS:
        return { ...state, isLoadingUser:false};
      case UPDATE_USER_POSITION_SUCCESS:
        console.log(action.payload)
        console.log('userPosition',state.userPosition)
        const newPosition:any = {...state.userPosition}
        newPosition[action.payload.user._id] = {position : action.payload.position, user: action.payload.user}
        console.log('newPosition ===>>> ',newPosition)
        return { ...state, userPosition:newPosition};
      // E assim por diante para os outros casos...
      default:
        return state;
    }
  }
  
    