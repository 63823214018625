import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, Tooltip, Zoom } from '@material-ui/core';
import { Button, Container, Typography, List, ListItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import store from './../redux/store';
//import { usuario } from '../redux/usuarios/actions';
import { fetchEmpresas } from '../redux/empresas/actions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { InputAdornment } from '@material-ui/core';
import { Email, Phone, Business, Link } from '@material-ui/icons';
import { getEmpresas } from '../api';
import { green } from '@material-ui/core/colors';
import moment from 'moment'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardMedia from '@mui/material/CardMedia';
import { UploadFile } from '@mui/icons-material';
import {
    useGridApiRef,
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridToolbar,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowEditStopReasons,
    GridValueGetterParams,
    GridToolbarExport,
} from '@mui/x-data-grid';
import { Autocomplete, Avatar, CardActions, CardHeader, Collapse, Divider, LinearProgress, ListItemButton, ListSubheader, Stack, styled } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';


import { client, AUTH_USER, AI_RESUME_FEEDBACK, getUsers, filterCompany, whoami, UPDATE_USER, REMOVE_COMPANY, CREATE_COMPANY, getCompanies, UPDATE_COMPANY, getCategories } from '../graphqlClient/graphql'

import { useMutation } from '@apollo/client';
import { serverConfig } from '../api/apiConfig';
import Notifications from './../components/notifications/Notifications';
import { width } from '@mui/system';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        textAlign: 'center',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        //alignItems: 'center',
        height: '100%',
    },
    content: {
        textAlign: 'center',
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    listContainer: {
        //maxWidth:'80%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        justifyContent: 'center',
        alignItems: 'flex-start',

        maxHeight: 'calc(100vh - 100px)',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'green',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'darkgreen',
        },
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    deleteButton: {
        marginLeft: theme.spacing(1),
    },
    addButton: {
        margin: theme.spacing(2),
        backgroundColor: green[500],
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
}));

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

const AiResumeFeedback: React.FC<PropsFromRedux> = ({ isLoadingUsuarios, isLoadingUsuariosByFilter, isLoadingCreateUsuario, empresas }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const messages: any = []
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
    const [selectedUsuario, setSelectedUsuario] = React.useState('');

    const [loading, setLoading] = React.useState(false);


    const [categories, setCategories] = React.useState<any>([]);
    const [companies, setCompanies] = React.useState<any>([]);
    const [selectedCategory, setSelectedCategory] = React.useState<any>({});
    const [selectedCompany, setSelectedCompany] = React.useState<any>({});
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [save, setSave] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState<any>({ key: 0, value: 'Filtro por operador' });
    const [usuario, setUsuario] = React.useState<any>({});
    const [messageData, setMessageData] = React.useState<any>('');
    //const [result, setResult] = React.useState<any>('### Análise do Currículo para a Vaga de Senior Civil Engineer: #### Pontos Positivos: - **Experiência Diversificada:** A candidata possui experiência em diversos projetos de engenharia civil em diferentes países como Irlanda, Noruega, Reino Unido, Estados Unidos e Bolívia, abrangendo áreas como infraestrutura, telecomunicações, e supervisão de obras civis. - **Habilidades Técnicas Avançadas:** Demonstrou habilidades avançadas em modelagem 3D, detalhamento de reforço estrutural, coordenação de modelos de construção, análise estrutural, gerenciamento de projetos, entre outras. - **Fluência em Vários Idiomas:** A candidata é fluente em espanhol, português e inglês, o que pode facilitar a comunicação em ambientes multiculturais e internacionais. #### Pontos a serem Melhorados: - **Formação Acadêmica Limitada:** Embora tenha formação em Engenharia Civil, seria interessante ver mais especializações ou cursos específicos na área de engenharia civil ou gerenciamento de projetos. - **Contextualização das Experiências:** Seria útil fornecer mais detalhes sobre o contexto e escala dos projetos nos quais a candidata trabalhou para fornecer uma visão mais completa de sua experiência. #### Nota da Avaliação: 8/10 Com base na análise do currículo da Stephanie Nuñez Nogales, ela apresenta uma sólida experiência em engenharia civil, habilidades técnicas avançadas e fluência em vários idiomas, o que é um grande diferencial para a vaga de Senior Civil Engineer. No entanto, a falta de detalhes adicionais sobre os projetos e uma formação acadêmica mais expansiva são áreas que poderiam ser melhoradas para enriquecer ainda mais seu perfil profissional.');
    const [result, setResult] = React.useState<any>('');
    const [finalResult, setFinalResult] = React.useState<any>('');
    const [startDate, setStartDate] = React.useState<any>(null);
    const [endDate, setEndDate] = React.useState<any>(null);
    const [expanded, setExpanded] = React.useState('');
    const [link, setLink] = React.useState('');
    const [file, setFile] = React.useState<File>();
    const [image, setImage] = React.useState<any>('');

    const [AiResumeFeedback, { data }] = useMutation(AI_RESUME_FEEDBACK);

    const getCategoriesAsync = async () => {

        const result: any = await getCategories()
        console.log('resutl ===>', result)

        if (result?.length) {
            setCategories(result)
        }
    }

    const getCompaniesAsync = async () => {

        const result: any = await getCompanies()
        console.log('resutl ===>', result)

        if (result?.length) {
            setCompanies(result)
        }
    }

    const getUser =  async () => {
        try {
          const user:any = await whoami();
          console.log('user ==>>',user)
          setUsuario(user);
        }
        catch (error) {
          console.log("Erro no getUser >>>>>", error)
        }
      }
  




    useEffect(() => {
        getUser();
        getCategoriesAsync();
        getCompaniesAsync();

    }, []);

    function formatText(inputText: string): string {
        // Substitui quebras de linha por <br>
        const formattedText0 = inputText.replace(/Nota da Avaliação: (\d\/10)/ , '<strong>Nota da Avaliação: $1</strong> <br>');
        const formattedText = formattedText0.replace('###', '');
        const formattedText1 = formattedText.replace(': -', ':');
        const formattedText2 = formattedText1.replace('. -', ':');
    
        // Aplica negrito para o texto entre ** **
        const boldText = formattedText2.replace(/\*\*(.*?)\*\*/g, '<br><strong>$1</strong>');
    
        return boldText;
    }


    useEffect(() => {
        if(result.length){
            let res = "";
            const parts = result.split("####").map((item:any)=>{
                res += `<p>${formatText(item)}</p>`

            })

        
        setFinalResult(res)
        }else{
            setFinalResult('')
        }
        

    }, [result]);




    useEffect(() => {
        if (isLoadingUsuarios) {
            setLoading(true)
        }
        if (loading && !isLoadingUsuarios) {

            setLoading(false)
        }
        console.log('isLoadingUsuarios ==> ', isLoadingUsuarios)

    }, [isLoadingUsuarios]);

    useEffect(() => {
        if (isLoadingCreateUsuario) {
            setLoading(true)
        }
        if (loading && !isLoadingCreateUsuario) {

            setLoading(false)
        }
        console.log('isLoadingCreateUsuario ==> ', isLoadingCreateUsuario)

    }, [isLoadingCreateUsuario]);

    const handleDeleteClick = (UsuarioId: any) => {
        console.log('UsuarioId', UsuarioId)
        setSelectedUsuario(UsuarioId);
        setDeleteConfirmationOpen(true);
    };

    const handleDeleteConfirmation = () => {
        console.log('selectedUsuario', selectedUsuario)
        //deleteUsuario(selectedUsuario);
        setDeleteConfirmationOpen(false);
    };






    //init DataGrid dependencies

    const clearData = (d: any) => {
        const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
        return dateTransform
    }









    const handleFileChangePdf = (event: any) => {
        const file = event.target.files[0];
        const fileSize = file.size / 1024 / 1024; // in MB
        const fileType = file.type;

        if (fileType !== 'application/pdf') {
            alert('Por favor, selecione apenas arquivos PDF.');
            event.target.value = null; // limpa o valor do input
        } else if (fileSize > 10) {
            alert('O arquivo selecionado é muito grande. Por favor, selecione um arquivo de até 10MB.');
            event.target.value = null; // limpa o valor do input
        } else {
            // processa o arquivo...
            setFile(event.target.files[0]);
        }
    };



    const sendMessage = async ()=>{
        console.log(messageData)
        const createChatgptInput:any = {
          user : usuario._id
        }
        if(file){
          console.log(file)
          createChatgptInput.pdf = file;
          //dataToSend.contador = (contador+1);
        } 
        // dataToSend.id = dataToSend._id
        delete createChatgptInput.__typename
        console.log('createChatgptInput',createChatgptInput)
        if(typeof createChatgptInput.pdf == 'string') delete createChatgptInput.pdf
        setLoading(true)
        try{
          const userToken = await AiResumeFeedback({
            variables: {
              createChatgptInput: createChatgptInput,
              
            },
            onCompleted: ({airesumefeedback} ) => {
              
              console.log('airesumefeedback result== > ',airesumefeedback);
              const notData = {
                type : 'success',
                message : 'user updating success',
                title : 'wescout',
                timeOut : 2500,
                callback : ()=>{},
                priority : true,
              }
              Notifications(notData)
              const res = airesumefeedback.response
              setResult(res)
              setLoading(false)
            }
          });
         } catch(error:any){
          const notData = {
            type : 'error',
            message : error.message,
            title : 'wescout',
            timeOut : 2500,
            callback : ()=>{},
            priority : true,
          }
          Notifications(notData)
          console.log("Erro ao atualizar usuário", error)
          setLoading(false)
         }
      }





    return (
        <Container className={classes.container}>
            <div className='root'>
                <div className={classes.content}>
                    {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}
                    <Typography variant="h4" className={'title'}>
                        AI Resune Feedback
                    </Typography>

                    {loading ? (
                        <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                        </Box>
                    ) : null}

                    <Box sx={{ width: '100%', height: 100, }} style={{ alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>
                        <Stack>
                            {/* {file&&file.name ? (<div>{file && `${file.name} - ${file.type}`}</div>):null} */}
                            <div className="file-upload">
                                {/* <img src={uploadImg} alt="upload" /> */}
                                {/* <Avatar sx={{ width: 108, height: 108 }} style={{margin:'0 auto'}} alt={usuario.name} src={image||usuario.avatar} /> */}
                                <UploadFile />
                                {file ? <div style={{ color: 'white' }}>file to upload: <span className='subtitle'>{file.name}</span> . </div> : null}
                                {/* <h3> {file?.name || "Choose a pdf file"}</h3> */}
                                <p>Maximum length 10mb</p>
                                {/* <input type="file" onChange={handleFileChange} /> */}
                                {
                                    !file?.name ? <label htmlFor="contained-button-file">
                                        <input
                                            accept="application/pdf"
                                            style={{ display: 'none' }}
                                            id="contained-button-file"
                                            type="file"
                                            onChange={handleFileChangePdf}
                                        />
                                        <Button variant="contained" component="span">
                                            Choose a pdf file
                                        </Button>
                                    </label> : (null)
                                }

                            </div>


                        </Stack>
                        <Stack borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
                            {
                                finalResult ? (
                                    <div id="chatbox" className='chatbox'>
                                <div dangerouslySetInnerHTML={{ __html: finalResult}} style={{width:'600px', height:'300px',padding:'15px', textAlign:'justify' , color:'white', margin:'0 auto', overflowY:'scroll'}}>
                                    
                                </div>
                               {/*  <TextField
                                    style={{ marginBottom: 5 }}
                                    label="type your message here"
                                    value={messageData}
                                    onChange={(e) => {
                                        setMessageData(e.target.value)
                                        messages.push(e.target.value)
                                    }}
                                /> */}
                            </div>
                                ) : null
                            }
                            <Button className={classes.addButton} variant="contained" color="primary" onClick={() => sendMessage()}>
                                Send
                            </Button>









                        </Stack>


                    </Box>





                    <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
                </div>
            </div>

            <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
                <DialogTitle>Confirm </DialogTitle>
                <DialogContent>
                    <Typography variant="body1">Are you sure you want to delete?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
                    <Button onClick={handleDeleteConfirmation} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>


        </Container>
    );
};

const mapStateToProps = (state: any) => ({


    isLoadingUsuarios: state.usuarios.isLoadingUsuarios,
    isLoadingUsuariosByFilter: state.usuarios.isLoadingUsuariosByFilter,
    isLoadingCreateUsuario: state.usuarios.isLoadingCreateUsuario,
    empresas: state.empresas.empresas
});

const mapDispatchToProps = {

};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AiResumeFeedback);
