
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import empresasReducer from './empresas/reducers';
import usuariosReducer from './usuarios/reducers';
import referenciasReducer from './referencias/reducers';
import authReducer from './auth/reducers';
import userReducer from './user/reducers';
import produtosReducer from './produtos/reducers';
import notificacoesReducer from './notificacoes/reducers';
import categoriasReducer from './categorias/reducers';
import globalReducer from './global/reducers';


// Combine os reducers de acordo com a estrutura do seu projeto
const rootReducer = combineReducers({
  empresas: empresasReducer,
  usuarios: usuariosReducer,
  auth:authReducer,
  user:userReducer,
  produtos:produtosReducer,
  referencias: referenciasReducer,
  categorias:categoriasReducer,
  notificacoes:notificacoesReducer,
  global:globalReducer

});

// Defina o tipo do estado global
export type RootState = ReturnType<typeof rootReducer>;

// Crie a store
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;

