import React , { useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { connect, ConnectedProps } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import Chart from './../components/Elements/Chart';
import Deposits from './../components/Elements/Deposits';
import Itens from './../components/Elements/Itens';
import Orders from './../components/Elements/Orders';
import { useNavigate } from 'react-router-dom';

import { fetchEmpresas } from '../redux/empresas/actions';

import { getCategorias, deleteCategoria, createCategoria, updateCategoria, clearErrorMessage } from '../redux/categorias/actions';
import { toggleDrawerStart } from '../redux/global/actions';

import Title from './../components/Elements/Title';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { axisClasses } from '@mui/x-charts';
import { BarChart } from '@mui/x-charts/BarChart';

import Stack from '@mui/material/Stack';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import moment from 'moment'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { Autocomplete, ListItemButton, ListSubheader } from '@mui/material';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import {client, AUTH_USER, getUsers, filterCompany, whoami, UPDATE_USER, REMOVE_COMPANY, CREATE_COMPANY,getCategories, getCompanies, UPDATE_COMPANY} from '../graphqlClient/graphql'
import { useMutation } from '@apollo/client';
import Notifications from './../components/notifications/Notifications';
import { UploadFile } from '@mui/icons-material';
import { serverConfig } from './../api/apiConfig';
function Copyright(props:any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://wescout.me/">
        wescout.me
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const zero = (n:any)=>{
  if(n<10)
   {return '0'+n} else return(n)

}
const getDate = ()=>{
  const date = new Date()
  const newDate = zero(date.getDate()) + '/' + zero(Number(date.getMonth())+1) + '/' + date.getFullYear()
  return newDate
}

type PropsFromRedux = ConnectedProps<typeof connector>;
const steps = ['Crie uma unidade', 'Capture ítens', 'Faça inventários'];

const Dashboard: React.FC<PropsFromRedux> = ({ categorias,errorMessage ,usuario}) => {
  const navigate = useNavigate();
  type PropsLista = {label:string;id:string,value:number} 

  const [dataName, setDataName] = React.useState<any>([]);
  const [dataNumber, setDataNumber] = React.useState<any>([]);
  const [inventariosTotal, setInventariosTotal] = React.useState<any>(0);
  const [lista, setLista] = React.useState<PropsLista[]>([]);
  const [lista1, setLista1] = React.useState<any>([]);
  const [listaLinha, setListaLinha] = React.useState<any[]>([]);
  const [listaLinhaData, setListaLinhaData] = React.useState<any>([]);
  const [listaLinha1, setListaLinha1] = React.useState<any[]>([]);
  const [listaLinha1Data, setListaLinha1Data] = React.useState<any>([]);
  const [listaDuplo, setListaDuplo] = React.useState<any>([]);
  const [listaDuplo1, setListaDuplo1] = React.useState<any>([]);
  const [lista2, setLista2] = React.useState<any>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [hasCategory, setHasCategory] = React.useState(false);
  const [intro, setIntro] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [nomeCategoria, setNomeCategoria] = React.useState('');
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [selectedCategory, setSelectedCategory] = React.useState<any>({key:0,value:'Filtro por unidade'});
  const [sending, setSending] = React.useState<any>(false);
  const [errorMessageText, setErrorMessageText] = React.useState<any>('');
  const [loading, setLoading] = React.useState(false);


  useEffect(()=>{
    clearErrorMessage()
    setHasCategory(false)
    setErrorMessageText('')
  },[])

/*   useEffect(()=>{
    
    if(inventariosCountByProduto.lista_prog&&inventariosCountByProduto.lista_prog.length){
      const list_prog = inventariosCountByProduto.lista_prog
      const newData = []
      const newData2 = []
      const newDataDuplo = []
      const newDataDuplo1 = []
      for(var z=0 ; z<list_prog.length;z++){
        const item = list_prog[z]
        const objDuplo = {
          nome: getCategoriaNome(item._id),
          id:item._id,
          completos: Number(item.completos),
          incompletos: Number(item.totalCount) - Number(item.completos),
          total: Number(item.totalCount),
          //color: "green",
          // legendFontColor: "#7F7F7F",
          // legendFontSize: 15
        }
        
        const obj = {
          label: getCategoriaNome(item._id)+' - Incompletos' ,
          id:item._id,
          value: item.totalCount - item.completos,
          //color: "green",
          // legendFontColor: "#7F7F7F",
          // legendFontSize: 15
        }
        const obj2 = {
          label: getCategoriaNome(item._id),
          value: item.completos,
          //color: "green",
          // legendFontColor: "#7F7F7F",
          // legendFontSize: 15
        }
        newData.push(obj)
        newData2.push(obj2)
        newDataDuplo.push(objDuplo)
        

      } 

      const objDuplo1 =[
        {
          dataKey : 'completos',
          label : 'Completos',
          valueFormatterInv 
          
          //color: "green",
          // legendFontColor: "#7F7F7F",
          // legendFontSize: 15
        },
        {
          dataKey : 'incompletos',
          label : 'Incompletos',
          valueFormatterInv 
        },
        {
          dataKey : 'total',
          label : 'Total',
          valueFormatterInv 
        },
      ] 
      
      console.log('newDataDuplo',newDataDuplo)
      console.log('newDataDuplo1',objDuplo1)
      
      setLista1(newData)
      setLista2(newData2)
      setListaDuplo(newDataDuplo)
      setListaDuplo1(objDuplo1)
    }
    
    setInventariosTotal(inventariosCountByProduto.total)
  },[inventariosCountByProduto]) */
 /*  useEffect(()=>{
    console.log('produtosCountByCategory', produtosCountByCategory)
    if(produtosCountByCategory&&produtosCountByCategory.length){
      const listaPie:any = []
      produtosCountByCategory.map((item:any)=>{
        const obj = {
          id: item._id,
          label: getCategoriaNome(item._id),
          value: item.count
        }
        listaPie.push(obj)

      })

      setLista(listaPie)

      
      
    }
    if(produtosCountByCategory.length){
      getInventariosContador()
    }

  },[produtosCountByCategory]) */

    


  /* useEffect(()=>{
    const order = (a:any,b:any)=>{
      return new Date(a._id).getTime() - new Date(b._id).getTime();
    }
    if(movprodTipo.length){
      console.log('movprodTipo',movprodTipo)
      const l_Linha:any = []
      const l_Linha1:any = []
      const l_LinhaData:any = []
      const l_Linha1Data:any = []
      const dataOrdered = movprodTipo.sort(order)
      console.log(dataOrdered)
      dataOrdered.map((item:any)=>{
        const data = clearData(item._id)
        if(item.entrada ){
          l_Linha.push(item.entrada)
          l_LinhaData.push(data)
        }
        if(item.Saida ){
          l_Linha1.push(item.Saida*-1)
          l_Linha1Data.push(data)
        }


      })
      console.log('l_linha',l_Linha)
      console.log('l_linhaData',l_LinhaData)
      setListaLinha(l_Linha)
      setListaLinhaData(l_LinhaData)
      setListaLinha1(l_Linha1)
      setListaLinha1Data(l_Linha1Data)
    }
  },[movprodTipo]) */



  /* useEffect(()=>{
    if(categorias.length) { 
      if(!hasCategory){
        setIntro(false)
      }
      if(!openMenu&&!intro)    
        {
          setOpenMenu(true)
          toggleDrawerStart()
          
        }
      
    }else{
      //setIntro(true)
    }
  },[categorias]) */



  useEffect(()=>{
    if(errorMessage?.message) {      
      setErrorMessageText(errorMessage?.message)
      
      console.log('errorMessage',errorMessage.message)
    }else{
      setErrorMessageText('')
    }
  },[errorMessage])


  const clearData = (d:any)=>{
    const dateTransform = (moment(d).format('YYYY, MM, DD'));
    const data = new Date(dateTransform)
    return data
  }



  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const checkCategoria = ()=>{
    console.log('checkCategoria')
    const novaCategoria = {
      nome: nomeCategoria,
      empresa: usuario.empresa,
      usuario : usuario._id,
      
    };
    console.log(novaCategoria)
    clearErrorMessage()
    createCategoria(novaCategoria);
    

  }

  const handleNext = () => {
    console.log('activeStep ===> ',activeStep)
    console.log('hasCategory ===> ',hasCategory)
    if(activeStep == 0&&!hasCategory/* &&!categorias.length */){
      setSending(true)
      checkCategoria()
      return;
    }
    console.log('passou')
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if(activeStep == steps.length -1)
      handleReset()
    
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    setIntro(false)
  };


  const chartSetting = {
    xAxis: [
      {
        label: 'Itens da unidade',
      },
    ],
    width: 600,
    height: 400,
    
  };
  const chartSettingInv = {
    yAxis: [
      {
        label: 'Inventários',
      },
    ],
    width: 600,
    height: 400,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-20px, 0)',
      },
    },
  };
  const valueFormatter = (value: any) => `${value} ítens`;
  const valueFormatterName = (value: any) =>getCategoriaNome(value);
  const valueFormatterInv = (value: any) => `${value} inventários`;
  const valueFormatterNameInv = (value: any) =>getCategoriaNome(value);
  const getCategoriaNome =(id:any)=>{

    const item = categorias.filter((el:any)=> el._id == id)
    if(!item.length) return id
    return item[0].nome
  }
  const changeCategoria = (cat:any)=>{
    
    console.log('categoria id: ',cat)
    
    /* if (!cat){
      getProdutos()
    }else{
      getProdutosByCategory(cat)
    } */
    
  }
  return (
    
      <Box sx={{ display: 'flex', width:'100%' }}>
        <CssBaseline />
        
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            
                //maxHeight: 'calc(100vh - 200px)',
                
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'green',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: 'darkgreen',
                },
              
          }}
        >
           
          {/* <Toolbar /> */}
          <Container maxWidth="lg" style={{width:'100%',alignContent:'center', textAlign:'center'}}>
            <Button className='backbutton' onClick={() => navigate('/')}>Go Back</Button>
          </Container>
          {loading ? (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            ) : null
          }
          {
            intro ?(
              
              <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                 <Grid container spacing={3}>
                {/* produtos */}

                <Grid item xs={12} md={12} lg={12} >
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 500,
                    }}
                  >

             {/*      <Box sx={{ width: '100%' }}>
                      <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                          const stepProps: { completed?: boolean } = {};
                          const labelProps: {
                            optional?: React.ReactNode;
                          } = {};
                          if (isStepOptional(index)) {
                            labelProps.optional = (
                              <Typography variant="caption">opcional</Typography>
                            );
                          }
                          if (isStepSkipped(index)) {
                            stepProps.completed = false;
                          }
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                        {activeStep === steps.length ? (
                          <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                              Todos etapas completadas - Pronto para fazer capturas de ítens pelo App.
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                              <Box sx={{ flex: '1 1 auto' }} />
                              <Button onClick={handleReset}>Finalizar</Button>
                            </Box>
                          </React.Fragment>
                        ) : (
                  <React.Fragment >
                    <Typography sx={{ mt: 2, mb: 1 }}>Passo {activeStep + 1}</Typography>
                    {
                      activeStep == 0 ? (
                        <Box sx={{   margin:15 }} style={{display: 'flex',flexDirection: 'column',height:50, alignContent: 'center', justifyContent: 'space-around', alignItems: 'center', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
                          <Typography sx={{ mt: 2, mb: 1 }}>Ítens quando capturados pela primeira vez precisam ser referenciados a uma Unidade. </Typography>
                          <Typography sx={{ mt: 2, mb: 1 }}>Unidades agrupam ítens para que possam ser inventariados posteriormente.</Typography>
                          <Typography color={'#008800'} sx={{ mt: 2, mb: 1 }}>Crie sua primeira Unidade agora.  </Typography>
                          <Typography sx={{ mt: 2, mb: 1 }}><TextField value={nomeCategoria} onChange={(v:any)=>{console.log(v.target.value);setNomeCategoria(v.target.value)}} id="standard-basic" label="Nome da Unidade" variant="standard" /></Typography>
                          {errorMessageText ? (
                            <Typography color={'red'} sx={{ mt: 2, mb: 1 }}>{errorMessageText}</Typography>
                            ):null}
                        </Box>
                      ) : null
                    }
                    {
                      activeStep == 1 ? (
                        <Box sx={{   margin:15 }} style={{display: 'flex',flexDirection: 'column',height:50, alignContent: 'center', justifyContent: 'space-around', alignItems: 'center', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
                          
                          <Typography sx={{ mt: 2, mb: 1 }}>A unidade {nomeCategoria} foi criada com sucesso!</Typography>
                          <Typography sx={{ mt: 2, mb: 1 }}>Utilize o Aplicativo e capture seu primeiro ítem, por QrCode ou Scanner RFID</Typography>
                        </Box>
                      ) : null
                    }
                    {
                      activeStep == 2 ? (
                        <Box sx={{   margin:15 }} style={{display: 'flex',flexDirection: 'column',height:50, alignContent: 'center', justifyContent: 'space-around', alignItems: 'center', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
                          <Typography sx={{ mt: 2, mb: 1 }}>Com ítens organizados em Unidades você já está pronto para fazer inventários</Typography>
                          <Typography sx={{ mt: 2, mb: 1 }}>Utilize o Aplicativo e faça inventários dos ítens registrados</Typography>
                        </Box>
                      ) : null
                    }
                    
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        voltar
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {isStepOptional(activeStep) && (
                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                          Pular
                        </Button>
                      )}
                      <Button disabled={isLoadingCategoria} onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                      </Button>
                    </Box>
                    </React.Fragment>
                      )}
                    </Box> */}
                

                    </Paper> 
                  </Grid>
                </Grid>
              </Container>
                    
                  
                  
              
            ):(
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            
              <Grid container spacing={3}>
                {/* produtos */}
                <Grid item xs={6} md={4} lg={4} >
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <React.Fragment>
                      <Title>Categories </Title>
                      <Typography component="p" variant="h2" >
                        {inventariosTotal}
                      </Typography>
                      <Typography color="text.secondary" sx={{ flex: 1 }}>
                        em {getDate()}
                      </Typography>
                      <div>
                        <Tooltip TransitionComponent={Zoom}   title="Categories" arrow>
                            <Button color="primary" onClick={()=>{navigate('/categories')}}>
                              Go to categories
                            </Button>
                          </Tooltip>
                        
                      </div>
                    </React.Fragment>
                  </Paper>
                </Grid>
                <Grid item xs={6} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <React.Fragment>
                      <Title>Companies </Title>
                      <Typography component="p" variant="h2" >
                        {inventariosTotal}
                      </Typography>
                      <Typography color="text.secondary" sx={{ flex: 1 }}>
                        em {getDate()}
                      </Typography>
                      <div>
                        <Tooltip TransitionComponent={Zoom}   title="Companies" arrow>
                            <Button color="primary" onClick={()=>{navigate('/companies')}}>
                              Go to companies
                            </Button>
                          </Tooltip>
                        
                      </div>
                    </React.Fragment>
                  </Paper>
                </Grid>
                {/* Chart */}
                <Grid item xs={6} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <React.Fragment>
                      <Title>Scout</Title>
                      <Typography component="p" variant="h2" >
                        {categorias.length}
                      </Typography>
                      <Typography color="text.secondary" sx={{ flex: 1 }}>
                        em {getDate()}
                      </Typography>
                      <div>
                        <Tooltip TransitionComponent={Zoom}   title="Scout" arrow>
                          <Button color="primary" onClick={()=>{navigate('/users')}}>
                          Go to scouts</Button>
                        </Tooltip>
                        
                      </div>
                    </React.Fragment>
                    
                  </Paper>
                </Grid>
                {/* <Grid item xs={12} >
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 500,
                    }}
                  >
                    <React.Fragment >
                      <Title>Movimentações: Entrada / Saída </Title>
                      {
                        listaLinha.length&&listaLinha1.length ? (
                          <Stack direction="column" sx={{ width: '100%' }}>
                            <Box sx={{ flexGrow: 1 }}>
                              <SparkLineChart
                                plotType="bar"
                                data={listaLinha}
                                //dataset={[{label:'valor',value:3} ,{label:'valor',value:-10} ,{label:'valor',value:-2} ,{label:'valor',value:5} ,{label:'valor',value:7} ,{label:'valor',value:-2} ,{label:'valor',value:4},{label:'valor',value:6}]}
                                 xAxis={{
                                  scaleType: 'time',
                                  data: listaLinhaData,
                                  valueFormatter: (value) => value.toISOString().slice(0, 10),
                                }} 
                                dataset={listaLinhaData}
                                height={200}
                                //curve="natural"
                                area
                                colors={['green','red']}
                                tooltip={{}}
                                showHighlight={true}
                                showTooltip={true}
                              />
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>
                              <SparkLineChart
                                plotType="bar"
                                data={listaLinha1}
                                dataset={listaLinha1Data}
                                //dataset={[{label:'valor',value:3} ,{label:'valor',value:-10} ,{label:'valor',value:-2} ,{label:'valor',value:5} ,{label:'valor',value:7} ,{label:'valor',value:-2} ,{label:'valor',value:4},{label:'valor',value:6}]}
                                xAxis={{
                                  scaleType: 'time',
                                  data: listaLinha1,
                                  valueFormatter: (value) => value.toISOString().slice(0, 10),
                                }} 
                                height={200}
                                //curve="natural"
                                area
                                colors={['red']}
                                tooltip={{}}
                                showHighlight={true}
                                showTooltip={true}
                              />
                            </Box>
                          </Stack>
                          
                        
                        ):null
                      }
                    </React.Fragment>
                  </Paper>


                </Grid> */}
                
                <Grid item xs={12} >
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 400,
                    }}
                  >
                    <React.Fragment >
                      <Title>Referrals by company </Title>
                      {
                        lista1.length ? (
                          <div style={{alignSelf:'center'}}>
                            <PieChart 
                            /*  series={[
                                {
                                  data: lista1
                                },
                              ]} */
                              series={[
                                { data: lista1, outerRadius: 50 },
                                {
                                  data: lista2,
                                  innerRadius: 50,
                                  arcLabel: (params) => params.label ?? 'uau',
                                },
                              ]}
                              width={800}
                              height={300}
                            />
                          </div>
                          
                        ):null
                      }
                    </React.Fragment>
                  </Paper>
                </Grid>
              
                
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                      <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
                  </Paper>
                </Grid>
              </Grid>
              
            </Container>
            )
          }
          
        </Box>
      </Box>
   
  );
}

const mapStateToProps = (state: any) => ({
    usuarios: state.usuarios.usuarios, 
    usuario: state.user.user, 
    empresas: state.empresas.empresas,
    produtos: state.produtos.produtos,
    categorias: state.categorias.categorias,
    inventories: state.produtos.inventories,
    produtosCountByCategory : state.produtos.produtosCountByCategory,
    movprodTipo : state.produtos.movprodTipo,
    inventariosCountByProduto : state.produtos.inventariosCountByProduto,
    isLoadingUsuarios : state.usuarios.isLoadingUsuarios,
    isLoadingProdutos : state.produtos.isLoadingProdutos,
    isLoadingCategoria : state.categorias.isLoadingCategoria,
    errorMessage : state.categorias.errorMessage,
  });
  
  const mapDispatchToProps = {
    
    
  };
  
  const connector = connect(mapStateToProps, mapDispatchToProps);
  
  export default connector(Dashboard);