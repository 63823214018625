// Importe as constantes de ação


import {

  FETCH_REFERENCIAS_SUCCESS,
  FETCH_REFERENCIA_BY_ID_SUCCESS,
  CREATE_REFERENCIA_SUCCESS,
  UPDATE_REFERENCIA_SUCCESS,
  UPDATE_REFERENCIA_START,
  GET_IMAGES_REFERENCIA,
  GET_IMAGES_REFERENCIA_FAILURE,
  GET_IMAGES_REFERENCIA_SUCCESS,
  SET_IMAGE_REFERENCIA_PROFILE_SUCCESS,
  DELETE_REFERENCIA,
  DELETE_REFERENCIA_SUCCESS
} from './actions';

// Estado inicial
const initialState = {
  referencias: [],
  referencia: null,
  isLoadingReferencia : false,
  isLoadingReferenciaUpdate : false,
  isLoadingReferenciaImages: false,
  isLoadingReferenciasDelete: false,
  imagesByReferencia : [],
  imageReferenciaProfile : ''
}

export default function referenciaReducer(state = initialState, action: { type: any; payload: any; }) {
  switch (action.type) {
    case FETCH_REFERENCIAS_SUCCESS:
      console.log('FETCH_REFERENCIAS_SUCCESS', action.payload)
      return { ...state, referencias: action.payload };
    case FETCH_REFERENCIA_BY_ID_SUCCESS:
      return { ...state, referencia: action.payload };
    case CREATE_REFERENCIA_SUCCESS:
      return { ...state, referencias: [...state.referencias, action.payload] };
    case UPDATE_REFERENCIA_START:
      console.log('UPDATE_REFERENCIA_START')
      return { ...state, isLoadingReferenciaUpdate:true};
    case UPDATE_REFERENCIA_SUCCESS:
      return { ...state, isLoadingReferenciaUpdate:false};
    // E assim por diante para os outros casos...
    case DELETE_REFERENCIA:
      console.log('DELETE_REFERENCIA')
      return { ...state, isLoadingReferenciasDelete:true};
    case DELETE_REFERENCIA_SUCCESS:
      return { ...state, isLoadingReferenciasDelete:false};
    // E assim por diante para os outros casos...
    case SET_IMAGE_REFERENCIA_PROFILE_SUCCESS:
        console.log('set_image_referencia_profile', action.payload)
        return { ...state, imageReferenciaProfile: action.payload };
      case GET_IMAGES_REFERENCIA:
        return { ...state, isLoadingReferenciaImages: true };
      case GET_IMAGES_REFERENCIA_SUCCESS:
        return { ...state, isLoadingReferenciaImages:false,  imagesByReferencia: action.payload };
    default:
      return state;
  }
}

  