import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, Tooltip, Zoom } from '@material-ui/core';
import { Button, Container, Typography, List, ListItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import store from './../redux/store';
//import { usuario } from '../redux/usuarios/actions';
import { fetchEmpresas } from '../redux/empresas/actions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopy from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { InputAdornment } from '@material-ui/core';
import { Email, Phone, Business, Link , Close} from '@material-ui/icons';
import { getEmpresas } from '../api';
import { green } from '@material-ui/core/colors';
import moment from 'moment'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardMedia from '@mui/material/CardMedia';
import {
  useGridApiRef,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { Autocomplete, Avatar, CardActions, CardHeader, Collapse, Divider, LinearProgress, ListItemButton, ListSubheader, Stack, styled } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';


import {client, AUTH_USER, scrapLink, getUsers,CREATE_RECOMMENDATION, scrapFilter, filterCompany, whoami, UPDATE_USER, REMOVE_COMPANY, CREATE_COMPANY, getCompanies, UPDATE_COMPANY, getCategories, filterRecommendation, filterCredit} from '../graphqlClient/graphql'
import { useMutation } from '@apollo/client';
import { serverConfig } from '../api/apiConfig';
import Notifications from './../components/notifications/Notifications';
import { alignProperty } from '@mui/material/styles/cssUtils';
import { AlignHorizontalCenter } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  listContainer: {
    //maxWidth:'80%',
    width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      justifyContent: 'center',
      alignItems: 'flex-start',
  
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'green',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  addButton: {
    margin: theme.spacing(2),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

const ScoutMePage: React.FC<PropsFromRedux> = ({ isLoadingUsuarios,user, isLoadingUsuariosByFilter, isLoadingCreateUsuario, empresas }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedUsuario, setSelectedUsuario] = React.useState('');
  const [addUsuarioModalOpen, setAddUsuarioModalOpen] = React.useState(false);
  const [newUsuarioName, setNewUsuarioName] = React.useState('');
  const [newUsuarioPassword, setNewUsuarioPassword] = React.useState('');
  const [newUsuarioConfirmPassword, setNewUsuarioConfirmPassword] = React.useState('');
  const [newUsuarioEmail, setNewUsuarioEmail] = React.useState('');
  const [newUsuarioTelefone, setNewUsuarioTelefone] = React.useState('');
  const [newUsuarioEmpresa, setNewUsuarioEmpresa] = React.useState('');
  const [editUsuarioData, setEditUsuarioData] = React.useState<any>({});
  const [usuarios, setUsuarios] = React.useState<any>([]);
  const [newUsuarioPermissoes, setNewUsuarioPermissoes] = React.useState<any>({});
  const [editUsuarioModalOpen, setEditUsuarioModalOpen] = React.useState(false);
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const permissoesList = ['administrador', 'gerente', 'basico']

  const [rows, setRows] = React.useState(usuarios);
  const [categories, setCategories] = React.useState<any>([]);
  const [companies, setCompanies] = React.useState<any>([]);
  const [companiesLinkedln, setCompaniesLinkedln] = React.useState<any>([]);
  const [companiesLinkedlnOrigin, setCompaniesLinkedlnOrigin] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>({});
  const [selectedCompany, setSelectedCompany] = React.useState<any>({});
  const [selectedCompanyLinkdln, setSelectedCompanyLinkdln] = React.useState<any>({});
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [save, setSave] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<any>({ key: 0, value: 'Filtro por operador' });

  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  const [expanded, setExpanded] = React.useState('');
  const [link, setLink] = React.useState('');
  const [jobsOrigin, setJobsOrigin] = React.useState<any>([]);
  const [jobs, setJobs] = React.useState<any>([]);
  const [referralsRemaining, setReferralsRemaining] = React.useState<any>(1);
  const [noCredits, setNoCredits] = React.useState<any>(false);
  const [openNoCredits, setOpenNoCredits] = React.useState<any>(false);

  const handleExpandClick = (id: string) => {
    const newValue = expanded == id ? '' : id
    setExpanded(newValue);
  };



  const apiRef = useGridApiRef();

  const [CreateRecommendationMutation, { data }] = useMutation(CREATE_RECOMMENDATION);

    const handleAddRecommendation = async () => {
      setLoading(true);
        const dataToSend = { 
            scout: user._id,
            active: true,
            company: selectedCompany._id,
            link,
            status : 'awaiting'

        }
        console.log('dataToSend', dataToSend)
        
        // dataToSend.id = dataToSend._id

        
        try{
          
          const createRecommendationInput = dataToSend
          console.log('createRecommendationInput', dataToSend)
          
          const result = await CreateRecommendationMutation({
            variables: {
              createRecommendationInput
            },
            onCompleted: ({createRecommendation} ) => {
              
              console.log('company created== > ',createRecommendation);
              const notData = {
                type : 'success',
                message : `Recommendation to ${createRecommendation?.company?.name} Created Successfully!` ,
                title : 'wescout',
                timeOut : 2500,
                callback : ()=>{},
                priority : true,
              }
              
              Notifications(notData)
              setLoading(false);
              setSelectedCompany({})
              navigate('/scout')
              //setCompany(updateUser);
            }
          });
        } catch(error:any){
          const notData = {
            type : 'error',
            message : error.message,
            title : 'wescout',
            timeOut : 2500,
            callback : ()=>{},
            priority : true,
          }
          Notifications(notData)
          console.log("Error at create credit", error)
      
        }
        
      };

      const checkCredit = async (u:any)=>{

        const objToSend = {
          //startDate,
          //endDate,
          //empresa:empresa
          //category:selectedCategory?._id || '',
          "user" : u._id || '',
          
        }
        //setIsLoadingCompaniesByFilter(true)
        console.log('objToSend', objToSend)
        
        const result = await filterCredit(objToSend)
        console.log('filterCredit result ==>>',result)
        if(result.length == 0){
            console.log('call add credit')
           // handleAddCredit()
        }else{
            const requested = result[0].recommendations.length
            //setReferralsRequested(requested)
            const credits = result[0].amount - requested
            console.log('credits', credits)
            setReferralsRemaining(credits)
        }
        //checkRecommendations(u)
        return result
        //getProductsByFilter(objToSend)
        //getCompaniesByFilter(objToSend)
        //setIsLoadingCompaniesByFilter(false)
        //setCompanies(result)
        //setSelectedCategory({})
      }
    const checkRecommendations = async (u:any)=>{

        const objToSend = {
          //startDate,
          //endDate,
          //empresa:empresa
          //category:selectedCategory?._id || '',
          "scout" : u._id || '',
          "active" : true
          
        }
        //setIsLoadingCompaniesByFilter(true)
        console.log('objToSend recommendations', objToSend)
        
        const result:any = await filterRecommendation(objToSend)
        console.log('filterRecommendation result ==>>',result)
        if(result.length == 0){
            console.log('call add credit')
            //handleAddRecommendations()
        }else{
            //setReferralsRemaining(result[0].amount)
            //setRecommendations(result)
        }
        return result
        //getProductsByFilter(objToSend)
        //getCompaniesByFilter(objToSend)
        //setIsLoadingCompaniesByFilter(false)
        //setCompanies(result)
        //setSelectedCategory({})
      }

  const getCategoriesAsync=async ()=>{
    
    const result:any = await getCategories()
    console.log('resutl ===>',result)
    
    if(result?.length){
      setCategories(result)
    }
  }

  const getCompaniesAsync=async ()=>{

    const result:any = await getCompanies()
    console.log('resutlgetCompanies ===>',result)
    
    if(result?.length){
      setCompanies(result)
    }
  }

  useEffect(() => {
    if (!isLoadingUsuarios && !isLoadingUsuariosByFilter && usuarios.length) {
      //setRows(usuarios)
      //apiRef.current.setColumnVisibility('_id', false);
    }

  }, [usuarios]);
  useEffect(() => {
    if (user?._id) {
      checkCredit(user)
    }

  }, [user]);
  useEffect(() => {
    if (!referralsRemaining) {
      setNoCredits(true)
    }else{
      setNoCredits(false)
    }

  }, [referralsRemaining]);

  
  useEffect(() => {
    ///getUsuarios();
    getCategoriesAsync();
    getCompaniesAsync();
    makeScrapFilter('ireland','data%20analyst')
  }, []);
  
  

  const handleDeleteConfirmation = () => {
    console.log('selectedUsuario', selectedUsuario)
    //deleteUsuario(selectedUsuario);
    setDeleteConfirmationOpen(false);
  };

  const clearNewUsuario = () => {
    setNewUsuarioName('');
    setNewUsuarioEmail('');
    setNewUsuarioTelefone('');
    setNewUsuarioEmpresa('');
    setNewUsuarioPassword('');
    setNewUsuarioConfirmPassword('');

  }

  const makePermissoes = () => {
    if (newUsuarioPermissoes == 'administrador') {
      return { admin: true }
    } else if (newUsuarioPermissoes == 'basico') {
      return { basic: true }
    } else if (newUsuarioPermissoes == 'gerente') {
      return { manager: true }
    } else {
      return {}
    }
  }



  const validateEmail = (email: any) => {
    return true
    // Implemente a lógica de validação do e-mail aqui
  };

  const handleEditUsuario = (usuario: any) => {
    console.log('usuario selecionada ==>> ', usuario)
    let perm = 'basico'
    if (usuario.permissoes?.admin) {
      perm = 'administrador'
    } else if (usuario.permissoes?.manager) {
      perm = 'gerente'
    }
    setNewUsuarioPermissoes(perm)
    setPasswordOpen(false);
    setNewUsuarioPassword('')
    setNewUsuarioConfirmPassword('')
    setEditUsuarioData(usuario);
    setEditUsuarioModalOpen(true);
  };

  const handleUpdateUsuario = () => {
    console.log(newUsuarioPassword)
    const dataToSend = { ...editUsuarioData }
    if (!newUsuarioPassword) {
      console.log('editUsuarioData', editUsuarioData)
      delete dataToSend.password
    }
    dataToSend.permissoes = makePermissoes();
    console.log('dataToSend', dataToSend)
    //updateUsuario(dataToSend)
    setEditUsuarioData({});
    setEditUsuarioModalOpen(false);
    // Lógica para atualizar a Usuario com os dados do modal
  };

  const validatePassword = (password: string | any[]) => {
    return password.length >= 6;
  };

  const validateConfirmPassword = (password: any, confirmPassword: any) => {
    return password === confirmPassword;
  };



  //init DataGrid dependencies

  const clearData = (d: any) => {
    const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
    return dateTransform
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row: any) => row._id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row: any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row: any) => row._id !== id));
    }
  };

  const processRowUpdate: any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow', newRow)
    if (newRow.isNew) {
      delete newRow.isNew
    }
    setEditUsuarioData(newRow)
    setRows(rows.map((row: any) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const checkPermissao = (perm: any) => {
    let permString = ''
    if (perm.admin) {
      permString = 'administrador'
    } else if (perm.manager) {
      permString = 'gerente'
    } else {
      permString = 'operador'
    }
    return permString

  }


  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Data',
      type: 'Date',
      width: 140,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => clearData(params.row.createdAt)
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
      //valueGetter : (params: GridValueGetterParams)=> getCategoriaNome(params.row.categoria)
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 150,
      editable: true,
      //valueGetter : (params: GridValueGetterParams)=> getCategoriaNome(params.row.categoria)
    },
    {
      field: 'role',
      headerName: 'regra',
      width: 120,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.role || ''
    },



    /* {
      field: 'identificacao',
      headerName: 'código',
      description: 'código do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].codigo || ''}  `,
    }, 
    {
      field: 'updatedAt',
      headerName: 'tipo identificacao',
      description: 'tipo de identificacao',
      sortable: false,
      width: 75,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].tipo || ''} `,
    }, */
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const cat = usuarios.filter((item: any) => item._id == id)[0]
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
            //onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [


          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip TransitionComponent={Zoom} title="Apagar" arrow>
                <IconButton size='small' className='iconDetail' color="primary" aria-label="Editar" >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            }
            label="Delete"
            onClick={() => {
              
            }}
            color="inherit"
          />,

        ];
      },
    },


  ];

  const hiddenFields = ['_id', 'id', 'ID'];

  const getTogglableColumns = (columns: GridColDef[]) => {
    console.log('getTogglableColumns', columns)
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };

  const changeFilterLinkedln = async (comp?:any)=>{
    setLoading(true)
    if(comp){
      console.log('comp ==> ',comp)
      const newList = jobsOrigin.filter((item:any)=>item.company == comp)
      if(newList.length){
        setJobs([...newList])
      }
      
    } else{
      setJobs([...jobsOrigin])
      }
      setLoading(false)

  }
  const changeFilter = async (cat?:any)=>{
    const referencia = cat || selectedCategory
    //if(!referencia._id) return;
    const objToSend = {
      startDate,
      endDate,
      //empresa:empresa
      category: referencia?._id || '',
      //usuario : selectedUser?._id || '',
      
    }
    //setIsLoadingCompaniesByFilter(true)
    console.log('objToSend', objToSend)
    
    const result = await filterCompany(objToSend)
    console.log('filter result ==>>',result)
    //getProductsByFilter(objToSend)
    //getCompaniesByFilter(objToSend)
    //setIsLoadingCompaniesByFilter(false)
    setCompanies(result)
    //setSelectedCategory({})
  }

  const makeScrap = async (location:any, job:any)=>{
    setLoading(true)
    const objToSend = {
      location,
      job,
      //empresa:empresa
      
      
    }
    //setIsLoadingCompaniesByFilter(true)
    console.log('objToSend', objToSend)
    
    const result = await scrapLink(objToSend)
    console.log('filter result ==>>',result)
    //getProductsByFilter(objToSend)
    //getCompaniesByFilter(objToSend)
    //setIsLoadingCompaniesByFilter(false)
    const scrapCompanies = []
    const resultScrap = result.result
    for(let z=0 ; z<resultScrap.length;z++){
      const comp = resultScrap[z].company
      scrapCompanies.push(comp)
    }
    setCompaniesLinkedlnOrigin([...scrapCompanies])
    setCompaniesLinkedln([...scrapCompanies])
    console.log('scrapCompanies ==> ',scrapCompanies)

    setJobs(resultScrap)
    setJobsOrigin(resultScrap)
    //setSelectedCategory({})
    setLoading(false)
  }
  const makeScrapFilter = async (location:any, job:any)=>{
    setLoading(true)
    const objToSend = {
      location,
      job,
      //empresa:empresa
      
      
    }
    //setIsLoadingCompaniesByFilter(true)
    console.log('objToSend', objToSend)
    
    const result = await scrapFilter(objToSend)
    console.log('filter scrapFilter ==>>',result)
    //getProductsByFilter(objToSend)
    //getCompaniesByFilter(objToSend)
    //setIsLoadingCompaniesByFilter(false)
    const scrapCompanies = []
    if(!result.length){
      return;
    }
    const resultScrap = result[0].result
    for(let z=0 ; z<resultScrap.length;z++){
      const comp = resultScrap[z].company
      const res = scrapCompanies.filter((item)=>item==comp)
      if(res.length==0){
        scrapCompanies.push(comp)
      }
      
    }
    setCompaniesLinkedln([...scrapCompanies])
    console.log('scrapCompanies ==> ',scrapCompanies)

    setJobs(resultScrap)
    setJobsOrigin(resultScrap)
    //setSelectedCategory({})
    setLoading(false)
  }

  const changeCategory = (cat:any)=>{
    console.log(cat)
    changeFilter(cat)
  }
  const handleSendLink = ()=>{
    console.log('handleSendLink')
    if(noCredits){
      setOpenNoCredits(true)
      return
    }
    handleAddRecommendation()
    
  }
  const clearSelected = ()=>{
    setSelectedCategory({})
    setSelectedCompany({})
  }





  return (
    <Container className={classes.container}>
      <div className='{classes.root}'>
        <div className={classes.content}>
          {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}
          <Typography variant="h4" className={'title'}>
            ScoutMe
          </Typography>
          
          {loading ? (
                        <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                        </Box>
                    ) : null}


          <Box sx={{ width: '100%', height: 100, }} style={{ alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>
            <Stack borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
              <Box sx={{ width: '100%' }} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', height: 30, alignContent: 'center', justifyContent: 'space-around', alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>
                <Autocomplete
                  getOptionLabel={(cat: any) => cat.name}
                  //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                  onChange={(event, newValue) => {
                    console.log(newValue)
                    if (!newValue) {
                      setSelectedCategory({})
                      changeCategory({})
                      
                    }
                    setSelectedCategory(newValue)
                    changeCategory(newValue)
                    //getInventoriesByCategory(newValue._id)
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={categories}
                  sx={{ width: 300 }}
                  renderInput={(params: any) => <TextField {...params} label="Category" />}
                />
                

              </Box>
              <Divider/>

              {/* <Box sx={{ width: '100%' }} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', height: 70, alignContent: 'center', justifyContent: 'center', alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker label="Initial"
                      //views={["day", "month", "year"]}
                      //view={["day", "month", "year"]}
                      // slots={{ toolbar: CustomToolbar }}
                      //slotProps={{ toolbar: {} }} 
                      slotProps={{
                        actionBar: {
                          actions: ['clear'],
                        },
                      }} 
                      format="DD/MM/YYYY"
                      onChange={(value: any) => {
                        if (!value) return
                        console.log('inicial', value.$d)
                        setStartDate(value.$d)
                      }}
                    />
                    <DatePicker label="Final"
                      format="DD/MM/YYYY"
                      onChange={(value: any) => {
                        if (!value) return
                        const valueDate = new Date(value.$d)
                        valueDate.setHours(23)
                        valueDate.setMinutes(59)
                        valueDate.setSeconds(59)
                        //console.log('day', day)
                        console.log('final', valueDate)
                        setEndDate(valueDate)
                      }}

                    />
                  </DemoContainer>
                </LocalizationProvider>
                <Button className='{classes.irButton}' onClick={() => changeFilter()}>Ir</Button>
              </Box> */}

            </Stack>


          </Box>

          {companies&&companies.length&&!selectedCompany?.name ? (
            <div className={classes.listContainer}>
              {companies.map((item:any)=>(
                <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor:'#0df228' }} aria-label="recipe">
                      {item.name.substring(0,2)}
                    </Avatar>
                  }
                  action={
                    <IconButton aria-label="settings">
                      <MoreVertIcon />
                    </IconButton>
                  }
                  title={item.name}
                  subheader="September 14, 2016"
                />
                <CardMedia
                onClick={ ()=> {
                  changeFilterLinkedln(item.name)
                  setSelectedCompany(item)} }
                  width={150}
                  component="img"
                  height="150"
                  image={ item.image ? serverConfig.dev.url + 'images/' + item.image : "https://robohash.org/"+item.name} 
                  alt="Companie image"
                />
                <CardContent>
                  <Typography variant="body2" color="primary">
                    {item.description}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                  </IconButton>
                  <IconButton aria-label="share">
                    <ShareIcon />
                  </IconButton>
                  <ExpandMore
                    expand={expanded == item._id  ? true : false}
                    onClick={()=>{handleExpandClick(item._id)}}
                    aria-expanded={expanded == item._id}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={expanded==item._id} timeout="auto" unmountOnExit>
                  <CardContent>
                    
                    <Typography paragraph>
                      
                    </Typography>
                    
                  </CardContent>
                </Collapse>
              </Card>
              )
                
              )}
             {/*  <DataGrid
                apiRef={apiRef}
                loading={isLoadingUsuarios || isLoadingUsuariosByFilter}
                getRowId={(row: { _id: any; }) => row._id}
                //checkboxSelection
                disableRowSelectionOnClick

                onClipboardCopy={(copiedString) => console.log(copiedString)}
                unstable_ignoreValueFormatterDuringExport
                autoHeight={true}
                rows={rows}
                columns={columns}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'createdAt', sort: 'asc' }],
                  },

                  pagination: {
                    paginationModel: {
                      pageSize: 15,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                //checkboxSelection

                //editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                // slots={{
                //   toolbar: EditToolbar,
                // }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    setRows, setRowModesModel, csvOptions: {
                      fileName: 'usuariosDataBase_',
                      delimiter: ';',
                      utf8WithBom: true,
                    },
                    columns: {
                      columnVisibilityModel: { ID: false, },
                    },
                    columnsPanel: {
                      getTogglableColumns,
                    },
                    printOptions: { hideToolbar: true, disableToolbarButton: false }
                  },
                }}



              //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },


              /> */}

            </div>
          ) : (<div style={{ margin: 15, color:"whitesmoke" }}>
            {selectedCompany.name}
            <Tooltip TransitionComponent={Zoom} title="Clear" arrow>
                <IconButton onClick={()=>clearSelected()} size='small' className='iconDetail' color="primary" aria-label="Editar" >
                  <Close />
                </IconButton>
              </Tooltip>
          </div>)}


          {
            selectedCompany.name ? (
              <Box  sx={{ width: '100%', alignItems:"center" }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>
                
                <Divider></Divider>
                <Typography component={'h2'} className='title'>
                  Paste a link to the job listing (with job description) by: {selectedCompany?.name}
                </Typography>
                <TextField
                  style={{maxWidth:"500px"}}
                  label="Paste the link here"
                  value={editUsuarioData.email}
                  onChange={(e) => setLink(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Link />
                      </InputAdornment>
                    ),
                  }}
                  error={!validateEmail(editUsuarioData.email)}
                  helperText={!validateEmail(editUsuarioData.email) && 'E-mail inválido'}
                />
                <Divider/>
                <Button className={classes.addButton} variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => handleSendLink()}>
                  Request referral
                </Button>
                
              </Box> 

            ) : null
          }


          {jobs&&jobs.length&&selectedCompany.name ? (
            <>
            
            <Divider/>
             <Box  sx={{ width: '100%', alignItems:"center" }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>

              <div className='title'>Linkedln jobs</div>
               <Autocomplete
                  
                  loading={!companiesLinkedln.length}
                  freeSolo={true}
                  getOptionLabel={(item: any) => item}
                  isOptionEqualToValue={(option:any, value:any) => {
                    console.log('option',option)
                    console.log('value',value)
                    return option.company === value
                  }}
                  
                  //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                  onChange={(event, newValue) => {
                    console.log('newValue ==>',newValue)
                    setSelectedCompanyLinkdln(newValue)
                    changeFilterLinkedln(newValue)

                  }}
                  disablePortal
                  id="combo-box-jobs"
                  options={companiesLinkedln}
                  sx={{ width: 300 }}
                  renderInput={(params: any) => <TextField {...params} label="Linkedln companies" />}
                /> 

              </Box> 
               
               <div className={classes.listContainer}>
               
              {jobs.map((item:any)=>(
                <Card sx={{ maxWidth: 345 }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor:'#0df228' }} aria-label="recipe">
                      {item.title.substring(0,2)}
                    </Avatar>
                  }
                  action={
                    <IconButton aria-label="settings">
                      <MoreVertIcon />
                    </IconButton>
                  }
                  title={item.title}
                  subheader={item.location}
                />
                {/* <CardMedia
                  onClick={ ()=> setSelectedCompany(item) }
                  component="img"
                  height="194"
                  image={ item.image ? serverConfig.dev.url + 'images/' + item.image : "https://robohash.org/"+item.name} 
                  alt="Companie image"
                /> */}
                <CardContent>
                  <Typography variant="body2" style={{color:'white'}}>
                    {item.company}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <IconButton onClick={()=>{
                    const notData = {
                      type : 'success',
                      message : `Link copied to clipboard` ,
                      title : 'wescout',
                      timeOut : 2500,
                      callback : ()=>{},
                      priority : true,
                    }
                    
                    
                    navigator.clipboard.writeText(item.link)
                    Notifications(notData)
                  }} aria-label="copy to clipboard">
                    <FileCopy />
                  </IconButton>
                  
                  <ExpandMore
                    expand={expanded == item._id  ? true : false}
                    onClick={()=>{handleExpandClick(item._id)}}
                    aria-expanded={expanded == item._id}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={expanded==item._id} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography className='subtitle'>
                    {item.link}
                    </Typography>
                    
                  </CardContent>
                </Collapse>
              </Card>
              )
                
              )}
        

            </div>
            </>
            
          ) : (<div style={{ margin: 15, color:"whitesmoke" }}>
            
          </div>)}

          {/* {usuarios.length ? (
            <div className={'listContainer'}>
              <List>
                {usuarios.map((usuario: any, index: any) => (
                  <ListItem key={index} className={classes.listItem} divider>
                    <Typography className='text'>{usuario.nome}</Typography>
                    <div>
                      <IconButton color="primary" aria-label="Editar" onClick={() => handleEditUsuario(usuario)}>
                        <EditIcon />
                      </IconButton>
                      
                      <IconButton
                        color="secondary"
                        aria-label="Deletar"
                        className={classes.deleteButton}
                        onClick={() => handleDeleteClick(usuario._id)}
                      >
                        
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
            
          ) : null} */}
          <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
        </div>
      </div>

      <Dialog open={openNoCredits} onClose={() => {setOpenNoCredits(false)}}>
        <DialogTitle>No credits </DialogTitle>
        <DialogContent>
          <Typography variant="body1">Buy some credits to continue</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setOpenNoCredits(false)}}>Not now</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            buy
          </Button>
        </DialogActions>
      </Dialog>

  
      
        


      {/* <Modal open={editUsuarioModalOpen} onClose={() => setEditUsuarioModalOpen(false)}>
  <div>
    
    <input
      type="text"
      value={editUsuarioData.cnpj}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdateUsuario}>Atualizar</button>
  </div>
</Modal> */}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({

  user: state.user.user,
  isLoadingUsuarios: state.usuarios.isLoadingUsuarios,
  isLoadingUsuariosByFilter: state.usuarios.isLoadingUsuariosByFilter,
  isLoadingCreateUsuario: state.usuarios.isLoadingCreateUsuario,
  empresas: state.empresas.empresas,

});

const mapDispatchToProps = {

};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ScoutMePage);
