import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Typography, List, ListItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal } from '@material-ui/core';
import {  InputAdornment,} from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl'; 
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';


import store from './../redux/store';
import { getNotificacoes,getTokens, deleteNotificacao, createNotificacao, updateNotificacao} from '../redux/notificacoes/actions';
import { fetchEmpresas } from './../redux/empresas/actions'
import { getUsuarios } from './../redux/usuarios/actions'
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { green } from '@material-ui/core/colors';
import { Email, Phone, Business } from '@material-ui/icons';
import {NotificationContainer, NotificationManager} from 'react-notifications';




const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100vh',
  },
  content: {
    textAlign: 'center',
  },
  listContainer: {
    maxHeight: 'calc(100vh - 200px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'green',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  text:{
    
    color:'#efdba0'
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minWidth : 400,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 300
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const NotificationsPage: React.FC<PropsFromRedux> = ({user, usuarios, getUsuarios, isLoadingNotificacao,tokens, getTokens, empresas, fetchEmpresas, notificacoes, getNotificacoes, deleteNotificacao, createNotificacao, updateNotificacao}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedNotificacao, setSelectedNotificacao] = React.useState('');
  const [addNotificacaoModalOpen, setAddNotificacaoModalOpen] = React.useState(false);
  const [newNotificacaoName, setNewNotificacaoName] = React.useState('');
  const [newNotificacaoTitle, setNewNotificacaoTitle] = React.useState('');
  const [newNotificacaoBody, setNewNotificacaoBody] = React.useState('');
  const [newNotificacaoEmpresa, setNewNotificacaoEmpresa] = React.useState('');
  const [editNotificacaoData, setEditNotificacaoData] = React.useState<any>({});
  const [notificacoesList, setNotificacoesList] = React.useState<any>({});
  const [editNotificacaoModalOpen, setEditNotificacaoModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

  const isAllSelected =
    usuarios.length > 0 && selected.length === usuarios.length;

  const handleChange = (event:any) => {
    const value = event.target.value;
    console.log('value  ===== ', value)
    if (value[value.length - 1] === "all") {
      const list = []
      const idsList = []
      for(let i=0 ; i < usuarios.length ; i++){
        list.push(usuarios[i].nome)
        idsList.push(usuarios[i]._id)

      }
      setSelected(selected.length === usuarios.length ? [] : list);
      setSelectedIds(selected.length === usuarios.length ? [] : idsList);
      return;
    }
    setSelected(value);
  };

  


  useEffect(() => {
    setAddNotificacaoModalOpen(false)
    getNotificacoes();
    
  }, [getNotificacoes]);
  useEffect(() => {
    fetchEmpresas();
  }, [fetchEmpresas]);
  useEffect(() => {
    getTokens();
  }, [getTokens]);
  useEffect(() => {
    getUsuarios();
  }, [getUsuarios]);

  useEffect(() => {
    console.log('tokens',tokens)
  }, [tokens]);
  useEffect(() => {
    console.log('usuarios',usuarios)
  }, [usuarios]);
  useEffect(() => {
    console.log('empresas',empresas)
  }, [empresas]);

  useEffect(() => {
    console.log('user ==>',user);
  }, [user]);
  useEffect(() => {

    if(notificacoes.length){
      const order = (a:any,b:any)=>{
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      }
      const orderedNotificacoes = notificacoes.sort(order)
      console.log('orderedNotificacoes', orderedNotificacoes)
      setNotificacoesList(orderedNotificacoes)
    }
  }, [notificacoes]);

  useEffect(() => {
    if(isLoadingNotificacao){
      setLoading(true)
    }
    if(loading && !isLoadingNotificacao){
      getNotificacoes();
      setLoading(false)
      NotificationManager.success('Executado com sucesso');
    }
    console.log('isLoadingNotificacao ==> ',isLoadingNotificacao)
    
  }, [isLoadingNotificacao]);

  const handleDeleteClick = (notId: string) => {
    setSelectedNotificacao(notId);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmation = () => {
    deleteNotificacao(selectedNotificacao);
    setDeleteConfirmationOpen(false);
  };

  const handleAddNotificacao = () => {
    const novaNotificacao = {
      nome: newNotificacaoName,
      empresa:user.empresa, //newNotificacaoEmpresa,
      title : newNotificacaoTitle,
      body : newNotificacaoBody,
    };
    console.log(novaNotificacao)
    createNotificacao(novaNotificacao);
    setAddNotificacaoModalOpen(false);
    setNewNotificacaoName('');
    setNewNotificacaoEmpresa('');

  };
  

  const validateEmail = (email:any) => {
    return true
    // Implemente a lógica de validação do e-mail aqui
  };
  
  
  
  
  const maskCNPJ = (cnpj:any) => {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]+/g, '');
    
    // Aplica a máscara
    cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    
    return cnpj;
  };
  
  const validatePhone = (phone:any) => {
    // Remove caracteres não numéricos
    phone = phone.replace(/[^\d]+/g, '');
    
    // Verifica se possui 11 dígitos (considerando o DDD)
    if (phone.length !== 11) {
      return false;
    }
    
    // Verifica se todos os dígitos são iguais (ex: 00000000000)
    if (/^(\d)\1+$/.test(phone)) {
      return false;
    }
    
    return true;
  };
  
  const maskPhone = (phone:any) => {
    // Remove caracteres não numéricos
    phone = phone.replace(/[^\d]+/g, '');
    
    // Aplica a máscara
    phone = phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    
    return phone;
  };

  const handleEditNotificacao = (categoria:any) => {
    console.log('categoria selecionada ==>> ', categoria)
    setEditNotificacaoData(categoria);
    setEditNotificacaoModalOpen(true);
  };
  
  const handleUpdateNotificacao = () => {
    console.log('editNotificacaoData',editNotificacaoData)
    updateNotificacao(editNotificacaoData)
    setEditNotificacaoData({});
    setEditNotificacaoModalOpen(false);
    // Lógica para atualizar a categoria com os dados do modal
  };
  
  
  

  return (
    <Container className={classes.container}>
      <div className='{classes.root}'>
          <div className={classes.content}>
          <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
          <Typography variant="h4" className={'title'}>
            Notificações
          </Typography>
          <Button className={classes.button} variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setAddNotificacaoModalOpen(true)}>
            Adicionar
          </Button>
          {notificacoesList.length ? (
            <div className={classes.listContainer}>
              <List>
                {notificacoesList.map((noti: any, index: any) => (
                  <div className={'listContainer'}>
                    <ListItem key={index} className={classes.listItem} divider>
                      <Typography className='text'>{noti.title}</Typography>
                      <div>
                        <IconButton color="primary" aria-label="Editar" onClick={() => handleEditNotificacao(noti)}>
                          <EditIcon />
                        </IconButton>
                        
                        <IconButton
                          color="secondary"
                          aria-label="Deletar"
                          className={classes.deleteButton}
                          onClick={() => handleDeleteClick(noti._id)}
                        >
                          
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </ListItem>
                  </div>
                  
                ))}
              </List>
            </div>
            
          ) : null}
          <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
        </div>
      </div>

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancelar</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={addNotificacaoModalOpen} onClose={() => setAddNotificacaoModalOpen(false)}>
        <DialogTitle>Adicionar Notificação</DialogTitle>
        <DialogContent className={classes.modalContent}>
        <FormControl fullWidth>
              <InputLabel id="demo-multiple-checkbox-label">Usuários</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selected}
                onChange={handleChange}
                input={<OutlinedInput label="Usuários selecionados" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : ""
                  }}
                >
                  
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        selected.length > 0 && selected.length < usuarios.length
                      }
                    />
                  
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Todos"
                  />
                </MenuItem>
                {usuarios.map((item:any) => (
                  <MenuItem key={item.nome} value={item.nome}>
                    
                    <Checkbox checked={selected.indexOf(item.nome) > -1} />
                    <ListItemText primary={item.nome} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          <TextField
            label="Nome da Notificação"
            value={newNotificacaoName}
            onChange={(e) => setNewNotificacaoName(e.target.value)}
          />
          <TextField
            label="Título da Notificação"
            value={newNotificacaoTitle}
            onChange={(e) => setNewNotificacaoTitle(e.target.value)}
          />
          <TextField
            label="Mensagem da Notificação"
            value={newNotificacaoBody}
            onChange={(e) => setNewNotificacaoBody(e.target.value)}
          />
          {/* <FormControl fullWidth>
              <InputLabel htmlFor="empresa-select">Selecione a Empresa</InputLabel>
              <Select
                value={newNotificacaoEmpresa}
                onChange={(e:any) => setNewNotificacaoEmpresa( e.target.value )}
                inputProps={{
                  name: 'empresa',
                  id: 'empresa-select',
                }}
              >
                {empresas.map((empresa: { _id: any; nome: any; }) => (
                  <MenuItem key={empresa._id} value={empresa._id}>
                    {empresa.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            
    
          
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddNotificacaoModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleAddNotificacao} color="primary">
            Criar
          </Button>
        </DialogActions>
      </Dialog>
      { editNotificacaoModalOpen && editNotificacaoData.nome ?
      <Dialog open={editNotificacaoModalOpen} onClose={() => setEditNotificacaoModalOpen(false)}>
        <DialogTitle>Editar Unidade</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome da Unidade"
            value={editNotificacaoData.nome}
            onChange={(e) => setEditNotificacaoData({ ...editNotificacaoData, nome: e.target.value })}
          />
          
          <TextField
            label="Empresa"
            value={editNotificacaoData.empresa}
            onChange={(e) => setEditNotificacaoData({...editNotificacaoData, empresa : e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Business />
                </InputAdornment>
              ),
            }}
            error={editNotificacaoData.empresa !== "" }

            helperText={
              !editNotificacaoData.empresa &&
              'Empresa inválida'
            }
            />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditNotificacaoModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleUpdateNotificacao} color="primary">
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>:null}

      
{/* <Modal open={editNotificacaoModalOpen} onClose={() => setEditNotificacaoModalOpen(false)}>
  <div>
    <input
      type="text"
      value={editNotificacaoData.nome}
      onChange={(e) => setEditNotificacaoData({ ...editNotificacaoData, nome: e.target.value })}
    />
    <input
      type="text"
      value={editNotificacaoData.email}
      onChange={(e) => setEditNotificacaoData({ ...editNotificacaoData, email: e.target.value })}
    />
    <input
      type="text"
      value={editNotificacaoData.empresa}
      onChange={(e) => setEditNotificacaoData({ ...editNotificacaoData, empresa: e.target.value })}
    />
    <input
      type="text"
      value={editNotificacaoData.cnpj}
      onChange={(e) => setEditNotificacaoData({ ...editNotificacaoData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdateNotificacao}>Atualizar</button>
  </div>
</Modal> */}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  empresas: state.empresas.empresas, 
  notificacoes: state.notificacoes.notificacoes, 
  usuarios: state.usuarios.usuarios, 
  tokens: state.notificacoes.tokens, 
  isLoadingNotificacao : state.notificacoes.isLoadingNotificacao,
  user : state.user.user
  
});

const mapDispatchToProps = {
    fetchEmpresas,
    getNotificacoes,
    getTokens,
    getUsuarios,
    deleteNotificacao,
    createNotificacao,
    updateNotificacao
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(NotificationsPage);
