import React, { ChangeEvent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {  Button, Container, FormControl, InputAdornment, InputLabel, Select, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { CloudUpload, Email, Person, Phone } from '@material-ui/icons';
import { ConnectedProps, connect } from 'react-redux';
import { updateUsuario, getImagesByUser, setImageProfile } from './../redux/usuarios/actions';
import { setUser } from './../redux/user/actions';
import { fetchEmpresas } from './../redux/empresas/actions';
import { deleteUsuario } from './../redux/usuarios/actions';
import { useNavigate } from 'react-router-dom';
import {serverConfig} from './../api/apiConfig';
import Avatar from '@mui/material/Avatar';
import { Box, Divider, LinearProgress, MenuItem } from '@mui/material';
import {client, AUTH_USER, getUsers, whoami, UPDATE_USER, getCompanies, getCategories} from '../graphqlClient/graphql'
import { useMutation } from '@apollo/client';
import Notifications from './../components/notifications/Notifications';


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {
    
    textAlign: 'center',
  },
  form_content: {
    marginTop:20,
      display: 'flex',
      flexDirection: 'column',
      aligContent: 'center',
      justifyContent: 'space-around',
      alignItems: 'stretch',
  
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  button:{
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  }
}));
type PropsFromRedux = ConnectedProps<typeof connector>;
const UsuarioPage: React.FC<PropsFromRedux>  = ({setImageProfile, setUser}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  
  const [editUsuarioData, setEditUsuarioData] = React.useState<any>({});
  const [newUsuarioPassword, setNewUsuarioPassword] = React.useState<any>('');
  const [userCompany, setUserCompany] = React.useState<any>('');
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [newUsuarioConfirmPassword, setNewUsuarioConfirmPassword] = React.useState('');

  const [loading, setLoading] = React.useState(false);

  const permissoesList = ['administrador','gerente','basico']
  const [contador, setContador] = React.useState(0);
  const [file, setFile] = React.useState<File>();
  const [image, setImage] = React.useState<any>('');
  const [update, setUpdate] = React.useState<any>(false);
  const [usuario, setUsuario] = React.useState<any>({});
  const [companies, setCompanies] = React.useState<any>([]);
  const [categories, setCategories] = React.useState<any>([]);
  
  const [UpdateUserMutation, { data }] = useMutation(UPDATE_USER);
 
  /* useEffect(() => {
    console.log("imagesByUser useEffect", imagesByUser)
    const imagesList:any = [];
    let cont = 0
    imagesByUser.map((item:any, index:any)=>{
      cont = (cont < item.contador ? item.contador : cont)
      const slide = {
        key: index,
        title: 'Usuario',
        text: '',
        image:serverConfig.dev.url + 'imagens/imagem/'+item._id,
        backgroundColor: 'green',
        
      }
      imagesList.push(slide)
    })
    console.log('imagesList ==>>> ', imagesList)
    console.log('cont ==>>> ', cont)
    setContador(cont)
    if(imagesList.length){
      const image = imagesList[0].image
      setImage(image)
      setImageProfile(image)
    }
    //setSlides(imagesList)
   
  },[imagesByUser]); */

  const getCategoriesAsync=async ()=>{

    const result:any = await getCategories()
    console.log('resutl ===>',result)
    
    if(result?.length){
      setCategories(result)
    }
    }

  const getCompaniesAsync=async ()=>{

    const result:any = await getCompanies()
    console.log('resutl ===>',result)
    
    if(result?.length){
      setCompanies(result)
      
    }
  }
  
  
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]))
    }
  };
const getUser =  async () => {
  setLoading(true)
      try {
        const user:any = await whoami();
        console.log('user ==>>',user)
        setUsuario(user);
      }
      catch (error) {
        console.log("Erro no getUser >>>>>", error)
      }
      setLoading(false)
    }
  useEffect(()=>{
    
    getUser();
    
  },[])
  useEffect(()=>{
    let perm = 'basico'
    if(usuario.permissoes?.admin) {
      perm = 'administrador'
    }else if(usuario.permissoes?.manager){
      perm = 'gerente'
    }
    setUserCompany(perm)
    setEditUsuarioData(usuario)
    if(usuario?.image){
      const imagePath = serverConfig.dev.url + 'images/' + usuario.image;
      setImage(imagePath)
      setImageProfile(imagePath)
      console.log('configurou o image profile')
    }
    if(usuario&&usuario.name) setUser(usuario)
  }, [usuario])

  const makePermissoes = ()=>{
    if(userCompany == 'administrador'){
      return {admin : true}
    } else if(userCompany == 'basico'){
      return {basic : true}
    } else if(userCompany == 'gerente'){
      return {manager : true}
    } else{
      return {}
    }
  }

  const updateUser:any=async (updateUserInput:any)=>{

    setLoading(true)
    
   try{
    const userToken = await UpdateUserMutation({
      variables: {
        updateUserInput: updateUserInput,
        
      },
      onCompleted: ({updateUser} ) => {
        
        console.log('user result== > ',updateUser);
        const notData = {
          type : 'success',
          message : 'user updating success',
          title : 'wescout',
          timeOut : 2500,
          callback : ()=>{},
          priority : true,
        }
        Notifications(notData)
        setUsuario(updateUser);
      }
    });
   } catch(error:any){
    const notData = {
      type : 'error',
      message : error.message,
      title : 'wescout',
      timeOut : 2500,
      callback : ()=>{},
      priority : true,
    }
    Notifications(notData)
    console.log("Erro ao atualizar usuário", error)

   }
   setLoading(false)
  }

  const handleUpdate = () => {
    setUpdate(true)
    setLoading(true)
    console.log('editUsuarioData',editUsuarioData)
    const dataToSend = {...editUsuarioData}
    //const permissoes = makePermissoes()
    //dataToSend.permissoes = permissoes
    if(file){
      console.log(file)
      dataToSend.image = file;
      //dataToSend.contador = (contador+1);
    } 
    // dataToSend.id = dataToSend._id
    delete dataToSend.__typename
    console.log('dataToSend',dataToSend)
    if(typeof dataToSend.image == 'string') delete dataToSend.image
    updateUser(dataToSend)
    setLoading(false)
    
  };

  const validateEmail = (email:any) => {
    return true
    // Implemente a lógica de validação do e-mail aqui
  };

  const validatePassword = (password: string | any[]) => {
    return password.length >= 6;
  };
  
  const validateConfirmPassword = (password: any, confirmPassword: any) => {
    return password === confirmPassword;
  };

  return (
    <Container className={classes.container}>
      <div className={'content'}>
      {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}
        <Typography variant="h4" className={'title'}>
          User profile
        </Typography>
        {loading ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}
        {/* Adicione os componentes e funcionalidades específicas da página do usuario */}
        <div className={classes.form_content}>
          
        <div>
        {/* {file&&file.name ? (<div>{file && `${file.name} - ${file.type}`}</div>):null} */}
        <div className="file-upload">
              {/* <img src={uploadImg} alt="upload" /> */}
              <Avatar sx={{ width: 108, height: 108 }} style={{margin:'0 auto'}} alt={usuario.name} src={image||usuario.avatar} />
              <h3> {file?.name || "Choose an image"}</h3>
              <p>Maximum length 10mb</p>
              <input type="file" onChange={handleFileChange} />
            </div>

            
        </div>
          <TextField
            variant="standard"
            ///style={{marginBottom:5}}
            label="user name"
            value={editUsuarioData.name}
            onChange={(e) => setEditUsuarioData({ ...editUsuarioData, name: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
            }}
          />
          <TextField
          style={{marginBottom:5}}
            label="E-mail"
            value={editUsuarioData.email}
            onChange={(e) => setEditUsuarioData({ ...editUsuarioData, email: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            error={!validateEmail(editUsuarioData.email)}
            helperText={!validateEmail(editUsuarioData.email) && 'E-mail inválido'}
          />
          
          { passwordOpen ? (
            <>
              <TextField
              style={{margin:5}}
              label="Senha"
              value={newUsuarioPassword}
              onChange={(e) => {
                setEditUsuarioData({ ...editUsuarioData, password: e.target.value })
                setNewUsuarioPassword(e.target.value)
              }}
              type="password"
              error={!validatePassword(newUsuarioPassword)}
              helperText={!validatePassword(newUsuarioPassword) && 'A senha deve ter pelo menos 6 caracteres'}
            />
            <TextField
            style={{margin:5}}
              label="Confirmar Senha"
              value={newUsuarioConfirmPassword}
              onChange={(e) => {
                setEditUsuarioData({ ...editUsuarioData, confirmPassword: e.target.value })
                setNewUsuarioConfirmPassword(e.target.value )
              }}
              type="password"
              error={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword)}
              helperText={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword) && 'As senhas não correspondem'}
            />
            </>

          ) : (<Button className={classes.button} onClick={() => setPasswordOpen(true)}>change password</Button>)
            
          }

          <TextField
          style={{marginBottom:5}}
            label="Phone"
            value={editUsuarioData.phone}
            onChange={(e) => setEditUsuarioData({ ...editUsuarioData, phone: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              ),
            }}
            /* error={!validateEmail(editUsuarioData.email)}
            helperText={!validateEmail(editUsuarioData.email) && 'E-mail inválido'} */
          />

          
            <FormControl fullWidth>
              <InputLabel htmlFor="permissao-select">select a category that interests you </InputLabel>
              <Select
                value={userCompany}
                onChange={(e:any) => setUserCompany( e.target.value )}
                inputProps={{
                  name: 'permissao',
                  id: 'permissao-select',
                }}
              >
                {permissoesList.map((permissao: any) => (
                  <MenuItem key={permissao} value={permissao}>
                    {permissao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


            <Button className={classes.button} style={{margin:15,}} onClick={()=>{handleUpdate()}}>update</Button>


          
          

            {/* <TextField
              label="Empresa"
              value={editUsuarioData.empresa}
              onChange={(e) => setNewUsuarioEmpresa(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Business />
                  </InputAdornment>
                ),
              }}
              error={editUsuarioData.empresa == ""}

              helperText={
                editUsuarioData.empresa=="" &&
                'Empresa inválida'
              }
            /> */}
        </div>
        <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
      </div>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  
});

const mapDispatchToProps = {
  setUser,
  setImageProfile
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(UsuarioPage);


