// Importe as constantes de ação


import {
    GET_USUARIOS,
    GET_USUARIOS_SUCCESS,
    GET_USUARIO_BY_ID_SUCCESS,
    UPDATE_USUARIO,
    UPDATE_USUARIO_SUCCESS,
    CREATE_USUARIO,
    CREATE_USUARIO_SUCCESS,
    DELETE_USUARIO,
    DELETE_USUARIO_SUCCESS,
    GET_IMAGES_SUCCESS,
    GET_IMAGES_FAILURE,
    GET_IMAGES,
    SET_IMAGE_PROFILE_SUCCESS,
    GET_USUARIOS_BY_FILTER,
    GET_USUARIOS_BY_FILTER_FAILURE,
    GET_USUARIOS_BY_FILTER_SUCCESS
    
  } from './actions';
  
  // Estado inicial
  const initialState = {
    isLoadingUsuarios : false,
    isLoadingUsuario : false,
    isLoadingUsuariosByFilter : false,
    isLoadingUsuariosImages : false,
    isLoadingCreateUsuario : false,
    usuarios: [],// Valor inicial do usuarios
    imagesByUser : [],
    imageProfile : ''
  }
  
  export default function usuariosReducer(state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
      case GET_USUARIOS_BY_FILTER:
        
        return {
        ...state,  
        isLoadingUsuariosByFilter : true,
      };
      case GET_USUARIOS_BY_FILTER_SUCCESS:
        console.log('GET_USUARIOS_BY_FILTER_SUCCESS', action.payload)
        return {
        ...state,  
        usuarios : action.payload,
        isLoadingUsuariosByFilter : false,
      };
      case GET_USUARIOS_BY_FILTER_FAILURE:
        console.log('GET_USUARIOS_BY_FILTER_FAILURE', action.payload)
        return {
        ...state,  
        errorMessage : action.payload,
        isLoadingUsuariosByFilter : false,
      };
      case SET_IMAGE_PROFILE_SUCCESS:
        console.log('set_image_profile', action.payload)
        return { ...state, imageProfile: action.payload };
      case GET_IMAGES:
        return { ...state, isLoadingUsuariosImages: true };
      case GET_IMAGES_SUCCESS:
        return { ...state, isLoadingUsuariosImages:false,  imagesByUser: action.payload };
      case GET_USUARIOS:
        return { ...state, isLoadingUsuarios: true };
      case GET_USUARIOS_SUCCESS:
        return { ...state, isLoadingUsuarios:false,  usuarios: action.payload };
      
      case CREATE_USUARIO:
        return { ...state, isLoadingCreateUsuario:true };
      case CREATE_USUARIO_SUCCESS:
        return { ...state, isLoadingCreateUsuario:false };
      case DELETE_USUARIO:
        return { ...state, isLoadingCreateUsuario:true };
      case DELETE_USUARIO_SUCCESS:
        return { ...state, isLoadingCreateUsuario:false };
      case UPDATE_USUARIO:
        return { ...state, isLoadingUsuario:true };
      case UPDATE_USUARIO_SUCCESS:
        return { ...state, isLoadingUsuario:false };
     
      // E assim por diante para os outros casos...
      default:
        return state;
    }
  }