
import { defaults } from '../state';

const resolvers = {
  Book: {
    count: () => 0
  },
  Query: {},
  Mutation: {}
  
};

export { resolvers };
