// Importe as constantes de ação


import {
    CREATE_CATEGORIA,
    CREATE_CATEGORIA_SUCCESS,
    CREATE_CATEGORIA_FAILURE,
    FETCH_CATEGORIAS_SUCCESS,
    FETCH_CATEGORIA_BY_ID_SUCCESS,
    
    UPDATE_CATEGORIA_SUCCESS,
    UPDATE_CATEGORIA_START,
    DELETE_CATEGORIA,
    DELETE_CATEGORIA_SUCCESS,
    CLEAR_ERROR_MESSAGE,
  } from './actions';
  
  // Estado inicial
  const initialState = {
    categorias: [],
    categoria: null,
    isLoadingCategoria : false,
    errorMessage : null
  }
  
  export default function categoriaReducer(state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
      case CLEAR_ERROR_MESSAGE:
        console.log('CLEAR_ERROR_MESSAGE', action.payload)
        return { ...state, errorMessage:action.payload };
      case FETCH_CATEGORIAS_SUCCESS:
        console.log('FETCH_CATEGORIAS_SUCCESS', action.payload)
        return { ...state, categorias: action.payload };
      case FETCH_CATEGORIA_BY_ID_SUCCESS:
        return { ...state, categoria: action.payload };
      case CREATE_CATEGORIA:
        return { ...state, isLoadingCategoria:true, };
      case CREATE_CATEGORIA_SUCCESS:
        console.log('CREATE_CATEGORIA_SUCCESS', action.payload)
        return { ...state, isLoadingCategoria:false, categorias: [...state.categorias, action.payload] };
      case CREATE_CATEGORIA_FAILURE:
        console.log('CREATE_CATEGORIA_FAILURE', action.payload)
        return { ...state, isLoadingCategoria:false, errorMessage:action.payload };
      case UPDATE_CATEGORIA_START:
        console.log('UPDATE_CATEGORIA_START')
        return { ...state, isLoadingCategoria:true};
      case UPDATE_CATEGORIA_SUCCESS:
        return { ...state, isLoadingCategoria:false};
      case DELETE_CATEGORIA:
        return { ...state, isLoadingCategoria:true};
      case DELETE_CATEGORIA_SUCCESS:
        return { ...state, isLoadingCategoria:false};
      // E assim por diante para os outros casos...
      default:
        return state;
    }
  }
  
    