import * as api from '../../api';
import RfidApi from '../../api/rfidApi';
import {serverConfig} from '../../api/apiConfig';
import * as base64 from 'base-64';
import { Console } from 'console';
// Defina o tipo de ação
//
export const FETCH_CATEGORIAS_SUCCESS = 'FETCH_CATEGORIAS_SUCCESS';
export const FETCH_CATEGORIA_BY_ID_SUCCESS = 'FETCH_CATEGORIA_BY_ID_SUCCESS';
export const CREATE_CATEGORIA_SUCCESS = 'CREATE_CATEGORIA_SUCCESS';
export const CREATE_CATEGORIA = 'CREATE_CATEGORIA';
export const CREATE_CATEGORIA_FAILURE = 'CREATE_CATEGORIA_FAILURE';
export const UPDATE_CATEGORIA_SUCCESS = 'UPDATE_CATEGORIA_SUCCESS';
export const UPDATE_CATEGORIA_START = 'UPDATE_CATEGORIA_START';
export const DELETE_CATEGORIA = 'DELETE_CATEGORIA';
export const DELETE_CATEGORIA_SUCCESS = 'DELETE_CATEGORIA_SUCCESS';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';



export const updateToken = async (renewToken: any)=> {
  const rfidApi = new RfidApi(renewToken);
      const response:any = await rfidApi.Fetch({
        method: 'post',
        url: `${serverConfig.pathUseCases.auth.authRefresh}`
      });

      if(response.data){
        localStorage.setItem('AUTH_USER_TOKEN_KEY', response.data.token)		  
    	  localStorage.setItem('AUTH_USER_RENEW_TOKEN_KEY', response.data.renewToken)		  
        return response.data.token
      }

}

export function* verifyToken (token: any){
  // Decodifica o token em arquivo json
  const decodeToken=()=> {
    const myToken = token
    if(! myToken ) {
      return null;
    }
    console.log('test token ===>>>', (base64.decode(myToken.split('.')[1])).toString())
    return (base64.decode(myToken.split('.')[1])).toString();
  }

  const isExpired =(): boolean => {
    let tokenProps:any = decodeToken();
    if(typeof tokenProps == 'string')
      tokenProps = JSON.parse(tokenProps)
    console.log('myToken', tokenProps  )
    if(! tokenProps ) {
      return true;
    }
    const dtExpire = 1000 * tokenProps['exp'];
    console.log('dtExpire', tokenProps['exp'],dtExpire, + new Date());
    return ( (+ new Date() )> dtExpire ); 
  }

  const condicao = isExpired()

  return condicao

}



export const getCategorias = () => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('fetchCategorias token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'get',
    body: {},
    url: serverConfig.pathUseCases.categorias.getCategorias,
  });
  console.log('response ==> ',response.data)
  dispatch({ type: FETCH_CATEGORIAS_SUCCESS, payload: response.data.data });
}

export const deleteCategoria = (categoriaId:any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: DELETE_CATEGORIA, payload: null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');

  console.log('deleteCategoria token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  
  const rfidApi = new RfidApi(token);


  const response: any = await rfidApi.Fetch({
    method: 'delete',
    body: {},
    url: serverConfig.pathUseCases.categorias.deleteCategoria(categoriaId),
  });
//const usuarios = await api.getusuarios();
console.log('response ==> ',response.data)
dispatch({ type: DELETE_CATEGORIA_SUCCESS, payload: response.data.data });
}

export const fetchCategoriaById = (id: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  //const categoria = await api.getCategoriaById(id);
  //dispatch({ type: FETCH_CATEGORIA_BY_ID_SUCCESS, payload: categoria });
}
export const clearErrorMessage = () => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: CLEAR_ERROR_MESSAGE, payload: {} });
}

export const createCategoria = (categoria: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: CREATE_CATEGORIA, payload: null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('fetchCategorias token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  console.log('categoria',categoria)
  try{
    const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'post',
    body: categoria,
    url: serverConfig.pathUseCases.categorias.createCategoria,
  });
  console.log('response create categoria ==> ',response)
  if(response.data?.data){
    dispatch({ type: CREATE_CATEGORIA_SUCCESS, payload: response.data?.data });
  } else{
    dispatch({ type: CREATE_CATEGORIA_FAILURE, payload: response.response.data });
  }
  } catch(error){
    console.log('error categoria===>',error)
    dispatch({ type: CREATE_CATEGORIA_FAILURE, payload: error});
  }
  
}
export const updateCategoria = (categoria: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: UPDATE_CATEGORIA_START, payload : null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('fetchCategorias token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  console.log('categoria',categoria)
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'patch',
    body: categoria,
    url: `${serverConfig.pathUseCases.categorias.updateCategoria(categoria._id)}`,
    
  });
  console.log('response edit categoria ==> ',response)
  getCategorias()
  dispatch({ type: UPDATE_CATEGORIA_SUCCESS, payload : null });
}



// E assim por diante para as outras ações...
