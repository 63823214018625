import { TOOGLE_DRAWER } from "./actions";






const initialState = {
    drawer: false
  }
  

export default function globalReducer(state = initialState, action: { type: any; payload: any; }) {
    const {payload,type} = action;
    switch (type) {
        
      case TOOGLE_DRAWER:
        return{
            ...state,
            drawer: !state.drawer
        }

      default:
        return state;
    }
  }