// Importe as constantes de ação


import {

    GET_NOTIFICACOES_SUCCESS,
    GET_NOTIFICACAO_BY_ID_SUCCESS,
    CREATE_NOTIFICACAO_SUCCESS,
    CREATE_NOTIFICACAO,
    DELETE_NOTIFICACAO_SUCCESS,
    DELETE_NOTIFICACAO,
    UPDATE_NOTIFICACAO_SUCCESS,
    UPDATE_NOTIFICACAO_START,
    GET_TOKENS_START,
    GET_TOKENS_SUCCESS,
  } from './actions';
  
  // Estado inicial
  const initialState = {
    notificacoes: [],
    notificacao: null,
    isLoadingNotificacao : false,
    tokens:[]
  }
  
  export default function notificacaoReducer(state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
      case GET_NOTIFICACOES_SUCCESS:
        console.log('GET_NOTIFICACOES_SUCCESS', action.payload)
        return { ...state, notificacoes: action.payload };
      case GET_NOTIFICACAO_BY_ID_SUCCESS:
        return { ...state, notificacao: action.payload };
      case CREATE_NOTIFICACAO:
        return { ...state, isLoadingNotificacao:true };
      case CREATE_NOTIFICACAO_SUCCESS:
        return { ...state, isLoadingNotificacao:false };
      case DELETE_NOTIFICACAO:
        return { ...state, isLoadingNotificacao:true };
      case DELETE_NOTIFICACAO_SUCCESS:
        return { ...state, isLoadingNotificacao:false };
      case UPDATE_NOTIFICACAO_START:
        console.log('UPDATE_NOTIFICACAO_START')
        return { ...state, isLoadingNotificacao:true};
      case UPDATE_NOTIFICACAO_SUCCESS:
        return { ...state, isLoadingNotificacao:false};
      case GET_TOKENS_START:
        console.log('GET_TOKENS_START')
        return { ...state, isLoadingNotificacao:true};
      case GET_TOKENS_SUCCESS:
        return { ...state, tokens:action.payload, isLoadingNotificacao:false};
      // E assim por diante para os outros casos...
      default:
        return state;
    }
  }
  
    