const defaults = {
  cart: {
    books: [],
    __typename: "Cart"
  },
  bookDetail: {
    comment: "",
    __typename: "BookDetail"
  }
};

export { defaults };
