import * as api from '../../api';
import RfidApi from '../../api/rfidApi';
import {serverConfig} from '../../api/apiConfig';
import * as base64 from 'base-64';
import axios from 'axios';
import {getReferencias} from '../referencias/actions'

// Defina o tipo de ação
export const GET_PRODUTOS = 'GET_PRODUTOS';
export const GET_PRODUTOS_SUCCESS = 'GET_PRODUTOS_SUCCESS';
export const GET_PRODUTOS_BY_CATEGORY = 'GET_PRODUTOS_BY_CATEGORY';
export const GET_PRODUTOS_BY_CATEGORY_SUCCESS = 'GET_PRODUTOS_BY_CATEGORY_SUCCESS';
export const GET_PRODUTOS_BY_FILTER = 'GET_PRODUTOS_BY_FILTER';
export const GET_PRODUTOS_BY_FILTER_SUCCESS = 'GET_PRODUTOS_BY_FILTER_SUCCESS';
export const GET_PRODUTOS_BY_FILTER_FAILURE = 'GET_PRODUTOS_BY_FILTER_FAILURE';
export const GET_PRODUTOS_COUNT = 'GET_PRODUTOS_COUNT';
export const GET_PRODUTOS_COUNT_SUCCESS = 'GET_PRODUTOS_COUNT_SUCCESS';
export const GET_PRODUTOS_COUNT_FAILURE = 'GET_PRODUTOS_COUNT_FAILURE';
export const GET_INVENTARIOS_COUNT = 'GET_INVENTARIOS_COUNT';
export const GET_INVENTARIOS_COUNT_SUCCESS = 'GET_INVENTARIOS_COUNT_SUCCESS';
export const GET_INVENTARIOS_COUNT_FAILURE = 'GET_INVENTARIOS_COUNT_FAILURE';
export const MOVPROD_CONSULTAR_TIPO = 'MOVPROD_CONSULTAR_TIPO';
export const MOVPROD_CONSULTAR_TIPO_SUCCESS = 'MOVPROD_CONSULTAR_TIPO_SUCCESS';
export const MOVPROD_CONSULTAR_TIPO_FAILURE = 'MOVPROD_CONSULTAR_TIPO_FAILURE';
export const GET_LOGS = 'GET_LOGS';
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';
export const GET_IMAGE = 'GET_IMAGE';
export const GET_IMAGE_SUCCESS = 'GET_IMAGE_SUCCESS';
export const GET_IMAGES_BY_PRODUCT = 'GET_IMAGES_BY_PRODUCT';
export const GET_IMAGES_BY_PRODUCT_SUCCESS = 'GET_IMAGES_BY_PRODUCT_SUCCESS';
export const UPDATE_PRODUTO = 'UPDATE_PRODUTO';
export const UPDATE_PRODUTO_SUCCESS = 'UPDATE_PRODUTO_SUCCESS';
export const UPDATE_PRODUTO_FAILED = 'UPDATE_PRODUTO_FAILED';
export const UPDATE_PRODUTOS = 'UPDATE_PRODUTOS';
export const UPDATE_PRODUTOS_SUCCESS = 'UPDATE_PRODUTOS_SUCCESS';
export const UPDATE_PRODUTOS_FAILED = 'UPDATE_PRODUTOS_FAILED';
export const DELETE_PRODUTO = 'DELETE_PRODUTO';
export const DELETE_PRODUTO_SUCCESS = 'DELETE_PRODUTO_SUCCESS';
export const CREATE_PRODUTO = 'CREATE_PRODUTO';
export const CREATE_PRODUTO_SUCCESS = 'CREATE_PRODUTO_SUCCESS';
export const GET_PRODUTO_BY_ID_SUCCESS = 'GET_PRODUTO_BY_ID_SUCCESS';

export const SET_PRODUTO_ID = 'SET_PRODUTO_ID';
export const SET_PRODUTO = 'SET_PRODUTO';
export const SET_LOG_SELECIONADO = 'SET_LOG_SELECIONADO';
export const GET_ALL_INVENTORY_START='GET_ALL_INVENTORY_START'
export const GET_ALL_INVENTORY_SUCCESS='GET_ALL_INVENTORY_SUCCESS'
export const GET_ALL_INVENTORY_FAILURE='GET_ALL_INVENTORY_FAILURE'
export const GET_INVENTORIES_BY_CATEGORY_START='GET_INVENTORIES_BY_CATEGORY_START'
export const GET_INVENTORIES_BY_CATEGORY_SUCCESS='GET_INVENTORIES_BY_CATEGORY_SUCCESS'
export const GET_INVENTORIES_BY_CATEGORY_FAILURE='GET_INVENTORIES_BY_CATEGORY_FAILURE'
export const GET_INVENTORIES_BY_FILTER_START='GET_INVENTORIES_BY_FILTER_START'
export const GET_INVENTORIES_BY_FILTER_SUCCESS='GET_INVENTORIES_BY_FILTER_SUCCESS'
export const GET_INVENTORIES_BY_FILTER_FAILURE='GET_INVENTORIES_BY_FILTER_FAILURE'
export const GET_LOGS_BY_FILTER_START='GET_LOGS_BY_FILTER_START'
export const GET_LOGS_BY_FILTER_SUCCESS='GET_LOGS_BY_FILTER_SUCCESS'
export const GET_LOGS_BY_FILTER_FAILURE='GET_LOGS_BY_FILTER_FAILURE'
export const CONSULT_PRODUCT_START='CONSULT_PRODUCT_START'
export const CONSULT_PRODUCT_SUCCESS='CONSULT_PRODUCT_SUCCESS'
export const CONSULT_PRODUCT_FAILURE='CONSULT_PRODUCT_FAILURE'
export const CLEAR_REDUCER= 'CLEAR_REDUCER'




export const updateToken = async (renewToken: any)=> {
  const rfidApi = new RfidApi(renewToken);
      const response:any = await rfidApi.Fetch({
        method: 'post',
        url: `${serverConfig.pathUseCases.auth.authRefresh}`
      });

      if(response.data){
        localStorage.setItem('AUTH_USER_TOKEN_KEY', response.data.token)		  
    	  localStorage.setItem('AUTH_USER_RENEW_TOKEN_KEY', response.data.renewToken)		  
        return response.data.token
      }

}

export function* verifyToken (token: any){
  // Decodifica o token em arquivo json
  const decodeToken=()=> {
    const myToken = token
    if(! myToken ) {
      return null;
    }
    console.log('test token ===>>>', (base64.decode(myToken.split('.')[1])).toString())
    return (base64.decode(myToken.split('.')[1])).toString();
  }

  const isExpired =(): boolean => {
    let tokenProps:any = decodeToken();
    if(typeof tokenProps == 'string')
      tokenProps = JSON.parse(tokenProps)
    console.log('myToken', tokenProps  )
    if(! tokenProps ) {
      return true;
    }
    const dtExpire = 1000 * tokenProps['exp'];
    console.log('dtExpire', tokenProps['exp'],dtExpire, + new Date());
    return ( (+ new Date() )> dtExpire ); 
  }

  const condicao = isExpired()

  return condicao

}



export const getInventariosContador = () => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: GET_INVENTARIOS_COUNT, payload: null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getProdutos token',token)
  //dispatch({ type: GET_PRODUTOS, payload: null });
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'post',
    body: {},
    url: serverConfig.pathUseCases.inventory.getInventariosContador,
  });
  console.log('response ==> ',response.data)
  dispatch({ type: GET_INVENTARIOS_COUNT_SUCCESS, payload: response.data.data });
}
export const getProdutosContador = () => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: GET_PRODUTOS_COUNT, payload: null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getProdutos token',token)
  //dispatch({ type: GET_PRODUTOS, payload: null });
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'post',
    body: {},
    url: serverConfig.pathUseCases.produtos.getProdutosContador,
  });
  console.log('response ==> ',response.data)
  dispatch({ type: GET_PRODUTOS_COUNT_SUCCESS, payload: response.data.data });
}
export const movprodConsultarTipo = () => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: MOVPROD_CONSULTAR_TIPO, payload: null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getProdutos token',token)
  //dispatch({ type: GET_PRODUTOS, payload: null });
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'post',
    body: {},
    url: serverConfig.pathUseCases.produtos.consultarTipo,
  });
  console.log('response ==> ',response.data)
  dispatch({ type: MOVPROD_CONSULTAR_TIPO_SUCCESS, payload: response.data.data });
}
export const getProdutos = () => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getProdutos token',token)
  //dispatch({ type: GET_PRODUTOS, payload: null });
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'get',
    body: {},
    url: serverConfig.pathUseCases.produtos.getProdutos,
  });
  console.log('response ==> ',response.data)
  dispatch({ type: GET_PRODUTOS_SUCCESS, payload: response.data.data });
}



export const getProdutosByCategory = (id:any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getProdutos token',token)
  dispatch({ type: GET_PRODUTOS_BY_CATEGORY, payload: null });
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'get',
    body: {},
    url: `${serverConfig.pathUseCases.produtos.getProductsByCategory(id)}`
    
  });
  console.log('response ==> ',response.data)
  dispatch({ type: GET_PRODUTOS_BY_CATEGORY_SUCCESS, payload: response.data.data });
  
}
export const getLogs = (_id:any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: GET_LOGS, payload: null });
  console.log('getLogs', _id)
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getProdutos token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'get',
    body: {},
    url: `${serverConfig.pathUseCases.produtos.getLogs(_id)}`,
  });
  console.log('response getLogs ==> ',response.data)
  dispatch({ type: GET_LOGS_SUCCESS, payload: response.data.data });
}

export const deleteProduto = (produtoId:any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  dispatch({ type: DELETE_PRODUTO, payload: null });
  
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  console.log('deleteProduto produtoId',produtoId)
    const rfidApi = new RfidApi(token);


      const response: any = await rfidApi.Fetch({
        method: 'delete',
        body: {},
        url: `${serverConfig.pathUseCases.produtos.deleteProduto(produtoId)}`,
      });
  //const produtos = await api.getprodutos();
  console.log('response ==> ',response.data)
  dispatch({ type: DELETE_PRODUTO_SUCCESS, payload: response.data.data });
}

export const getProdutoById = (id: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  //const produto = await api.getProdutoById(id);
  //dispatch({ type: GET_PRODUTO_BY_ID_SUCCESS, payload: produto });
}
export const setProdutoId = (id: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  //const produto = await api.getProdutoById(id);
  dispatch({ type: SET_PRODUTO_ID, payload : id });
}
export const setProduto = (obj: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  //const produto = await api.getProdutoById(id);
  dispatch({ type: SET_PRODUTO, payload : obj });
}
export const setLogSelecionado = (param: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  //const produto = await api.getProdutoById(id);
  dispatch({ type: SET_LOG_SELECIONADO, payload : param });
}

export const createProduto = (produto: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getProdutos token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  console.log('produto',produto)
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'post',
    body: produto,
    url: serverConfig.pathUseCases.produtos.createProdutos,
  });
  console.log('response create produto ==> ',response)
  dispatch({ type: CREATE_PRODUTO_SUCCESS, payload: response.data.data });
}


export const uploadImage = async(data: any, file:any, token:any)=> {
  var axiosResponse
  const fileData = data;
  console.log('fileData init ===>>>>', fileData)
  console.log('fileData init ===>>>>', token)

 
    const config = {
        headers: {
          //Accept: '*/*',
          'Authorization':'Bearer ' + token,
          'Content-Type': 'multipart/form-data; charset=utf-8; boundary="another cool boundary";',
                
                //charset=utf-8; boundary="conteudo";
                //'x-amz-acl' : 'public-read'
        }
    };

      const form = new FormData();
      //form.append('data', JSON.stringify(data));
       Object.keys(fileData).forEach((key) => {
        console.log(key)
          form.append(key, fileData[key]);
      }); 
    
    
      if(file && !file.type && file.uri){
        const list = file.uri.split('.')
        const len = list.length
        file['type'] = 'image/'+list[len-1]
      }
      if(!file.name)
        file['name'] = file.fileName;

      
      console.log("file.type",file)
      if(file && file.type){
        form.append("arquivo", file);
        //form.append('Content-Type',file.type)
        console.log('form 1 ====>> ',form)
        
          let endPoint = `${serverConfig.dev.url}${serverConfig.pathUseCases.produtos.uploadImage}`
          console.log('endPoint',endPoint)
          console.log('config',config)
          /* responseUpload = yield axios.post(endPoint, form, config).then(response => {
            console.log('response ===> ', response)
          })
          .catch(error => {
            // Lógica para lidar com erros da requisição
            console.log('erro upload ===>>> ', error)
          }); */


          try {
            axiosResponse = await axios.request({
              url: endPoint,
              method: 'post',
              data: form,
              headers: config.headers
              // withCredentials: true,
            });
            
            
          } catch (error) {
            axiosResponse = error;
            console.log('error api : ', axiosResponse);
          }
          console.log('api response upload : ', axiosResponse);
          

      }
      return axiosResponse;

  
}

export const updateProduto = (produto: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: UPDATE_PRODUTO, payload : null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getProdutos token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  console.log('produto',produto)
  if(produto.file){
    console.log('has file *****************')
    const dataToSend = 
      {
        "referencia": "produto",
        "idReferencia": produto._id,
        "titulo": "imagem",
        "descricao": "capa",
        "contador": produto.contador
      }

      delete produto.contador


      //console.log('has file *****************', dataToSend )
      //console.log('has file *****************', produto.file )
      try{
          const sendFile = await uploadImage(dataToSend, produto.file, token);
      } catch(err){
        console.log('erro sendfile ===>>> ',err)

      }
    delete produto.file
  }
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'patch',
    body: produto,
    url: `${serverConfig.pathUseCases.produtos.updateProduto(produto._id)}`,
    
  });
  console.log('response edit produto ==> ',response)
  getProdutos()
  dispatch({ type: UPDATE_PRODUTO_SUCCESS, payload : null });
}
export const updateProdutos = (data: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: UPDATE_PRODUTOS, payload : null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getProdutos token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  
  
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'patch',
    body: data,
    url: `${serverConfig.pathUseCases.produtos.updateProdutos}`,
    
  });
  console.log('response editMany produtoS ==> ',response)
  //getReferencias()
  dispatch({ type: UPDATE_PRODUTOS_SUCCESS, payload : null });
}

export const getImagesByProduct = (payload: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  try {
    dispatch({ type: GET_IMAGES_BY_PRODUCT, payload: null });
    let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
    const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
    
    console.log('token getUserAsync',token)
    let vt = await verifyToken(token);
    if(vt){
      token = await updateToken(renewToken)
      console.log('refreshToken ', token)
    }

    const params:any = {referencia : 'produto' , idReferencia: payload}

    const rfidApi = new RfidApi(token);
    const response:any = await rfidApi.Fetch({
      method: 'post',
      body: params,
      url: `${serverConfig.pathUseCases.produtos.getImages}`,
    });

    console.log('getImagesByProductStart  =====>>>>>>    ', response.data)
    dispatch({ type: GET_IMAGES_BY_PRODUCT_SUCCESS, payload: response.data.data });
    //await put(getImagesByProductSuccess(response.data.data));

  } catch (error) {
   // await put(getAllProductsFailure('erro'));
  }
}
export const getInventoriesByFilter = (payload: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  try {
    dispatch({ type: GET_INVENTORIES_BY_FILTER_START, payload: null });
    let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
    const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
    
    let vt = await verifyToken(token);
    if(vt){
      token = await updateToken(renewToken)
      console.log('refreshToken ', token)
    }

    const params:any = payload;

    const rfidApi = new RfidApi(token);
    const response:any = await rfidApi.Fetch({
      method: 'post',
      body: params,
      url: `${serverConfig.pathUseCases.inventory.getInventoriesByFilter}`,
    });

    console.log('GET_INVENTORIES_BY_FILTER_SUCCESS  =====>>>>>>    ', response.data.data)
    dispatch({ type: GET_INVENTORIES_BY_FILTER_SUCCESS, payload: response.data.data.data });
    //await put(getImagesByProductSuccess(response.data.data));

  } catch (error) {
    dispatch({ type: GET_INVENTORIES_BY_FILTER_FAILURE, payload: error });
   // await put(getAllProductsFailure('erro'));
  }
}
export const getProdutosByFilter = (payload: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  try {
    dispatch({ type: GET_PRODUTOS_BY_FILTER, payload: null });
    let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
    const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
    
    let vt = verifyToken(token);
    if(vt){
      token = await updateToken(renewToken)
      console.log('refreshToken ', token)
    }

    const params:any = payload;

    const rfidApi = new RfidApi(token);
    const response:any = await rfidApi.Fetch({
      method: 'post',
      body: params,
      url: `${serverConfig.pathUseCases.produtos.getProdutosByFilter}`,
    });

    console.log('GET_INVENTORIES_BY_FILTER_SUCCESS  =====>>>>>>    ', response.data.data)
    dispatch({ type: GET_PRODUTOS_BY_FILTER_SUCCESS, payload: response.data.data.data });
    //await put(getImagesByProductSuccess(response.data.data));

  } catch (error) {
    dispatch({ type: GET_PRODUTOS_BY_FILTER_FAILURE, payload: error });
   // await put(getAllProductsFailure('erro'));
  }
}
export const getLogsByFilter = (payload: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  try {
    dispatch({ type: GET_LOGS_BY_FILTER_START, payload: null });
    let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
    const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
    
    let vt = verifyToken(token);
    if(vt){
      token = await updateToken(renewToken)
      console.log('refreshToken ', token)
    }

    const params:any = payload;

    const rfidApi = new RfidApi(token);
    const response:any = await rfidApi.Fetch({
      method: 'post',
      body: params,
      url: `${serverConfig.pathUseCases.produtos.getLogsByFilter}`,
    });

    console.log('GET_INVENTORIES_BY_FILTER_SUCCESS  =====>>>>>>    ', response.data.data)
    dispatch({ type: GET_LOGS_BY_FILTER_SUCCESS, payload: response.data.data.data });
    //await put(getImagesByProductSuccess(response.data.data));

  } catch (error) {
    dispatch({ type: GET_LOGS_BY_FILTER_FAILURE, payload: error });
   // await put(getAllProductsFailure('erro'));
  }
}
export const getSelectedImage = (payload: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {

  try {
    dispatch({ type: GET_IMAGE, payload: null });
    let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
    const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
    
    
    console.log('token getUserAsync',token)
    let vt =  verifyToken(token);
    if(vt){
      token = await updateToken(renewToken)
      console.log('refreshToken ', token)
    }

    const rfidApi = new RfidApi(token);
    const response:any = await rfidApi.Fetch({
      method: 'get',
      url: `${serverConfig.pathUseCases.produtos.getSelectedImage(payload)}`,
    });

    console.log('selectedimage  =====>>>>>>    ', response.data)
    dispatch({ type: GET_IMAGE_SUCCESS, payload: response.data.data });
  } catch (error) {
    //await put(getAllProductsFailure('erro'));
  }
}


export const  getAllInventoryStart=()=>async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  
  dispatch({ type: GET_ALL_INVENTORY_START, payload: null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
    const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
    console.log('token getUserAsync',token)
    let vt =  verifyToken(token);
    if(vt){
      token = await updateToken(renewToken)
      console.log('refreshToken ', token)
    }

    try {

      const rfidApi = new RfidApi(token);
      const response:any = await rfidApi.Fetch({
        method: 'get',
        url: `${serverConfig.pathUseCases.inventory.getAllInventories}`,
      });
  
      console.log('getAllInventorySuccess  =====>>>>>>    ', response.data)
      dispatch({ type: GET_ALL_INVENTORY_SUCCESS, payload: response.data.data });
  
    } catch (error) {
      dispatch({ type: GET_ALL_INVENTORY_FAILURE, payload: error });
      
    }
};
export const  getInventoriesByCategory=(id:any)=>async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  
  dispatch({ type: GET_INVENTORIES_BY_CATEGORY_START, payload: null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
    const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
    console.log('token getUserAsync',token)
    let vt =  verifyToken(token);
    if(vt){
      token = await updateToken(renewToken)
      console.log('refreshToken ', token)
    }

    try {

      const rfidApi = new RfidApi(token);
      const response:any = await rfidApi.Fetch({
        method: 'get',
        url: `${serverConfig.pathUseCases.inventory.getInventoriesByCategory(id)}`,
      });
  
      console.log('getAllInventorySuccess  =====>>>>>>    ', response.data)
      dispatch({ type: GET_INVENTORIES_BY_CATEGORY_SUCCESS, payload: response.data.data });
  
    } catch (error) {
      dispatch({ type: GET_INVENTORIES_BY_CATEGORY_FAILURE, payload: error });
      
    }
};

export const  consultProductStart = (data: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  try{
    dispatch({ type: CONSULT_PRODUCT_START, payload: null });
    let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
    const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
    
    
    console.log('token getUserAsync',token)
    let vt =  verifyToken(token);
    if(vt){
      token = await updateToken(renewToken)
      console.log('refreshToken ', token)
    }

    console.log('consult product ===>>> ', data)
    const identificacao:any = data
    //payload.identificacao[0]
    console.log(identificacao)

    const rfidApiCheck = new RfidApi(token);
    
    const responseCheck:any = await rfidApiCheck.Fetch({
      method: 'post',
      body : identificacao,
      url: `${serverConfig.pathUseCases.produtos.searchCode}`,
    });
    const produto_response = responseCheck?.data?.data
    console.log('responseCheck',produto_response)
    const key = identificacao.codigo
    const finalResponse = produto_response.length ? { "key" : key , data : produto_response[0]  } : { "key":key, data : false }
    console.log('finalResponse',finalResponse)
    dispatch({ type: CONSULT_PRODUCT_SUCCESS, payload: finalResponse });

  } catch(err){
    console.log('err ==>>> ',err)
    dispatch({ type: CONSULT_PRODUCT_FAILURE, payload: err });

  }
}




// E assim por diante para as outras ações...
