import React from 'react';
import './Footer.css';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Container from '@mui/material/Container';

import logo_color from './../../assets/images/logo_footer.png'
function Copyright(props: any) {
  return (
    <Typography variant="body2" color="#05f228" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://wescout.me/" target="_blank">
        wwww.wescout.me
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const Footer = () => {
  return (
    // <footer className="footer">
    //   {/* Conteúdo do rodapé */}
      
    // </footer>
    <Box
    component="footer"
    sx={{

      padding: 0,
      zIndex:2,
      position: 'absolute',
      bottom: '-150px',
      width:'100vw',
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[800],
    }}
  >
    <Container maxWidth="sm" style={{textAlign: 'center'}}>
      {/* <Typography variant="body1"></Typography> */}
      <img src={logo_color} height={70} alt="" />
      
      <Copyright />
    </Container>
  </Box>
    

  );
};

export default Footer;
