import React, { ChangeEvent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, Tooltip, Zoom } from '@material-ui/core';
import { Button, Container, Typography, List, ListItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import store from './../redux/store';
//import { usuario } from '../redux/usuarios/actions';
import { fetchEmpresas } from '../redux/empresas/actions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { InputAdornment } from '@material-ui/core';
import { Email, Phone, Business, Link } from '@material-ui/icons';
import { getEmpresas } from '../api';
import { green } from '@material-ui/core/colors';
import moment from 'moment'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardMedia from '@mui/material/CardMedia';
import {
  useGridApiRef,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { Autocomplete, Avatar, CardActions, CardHeader, Collapse, Divider, LinearProgress, ListItemButton, ListSubheader, Stack, styled } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';


import {client, AUTH_USER, getUsers, filterCompany, whoami, UPDATE_USER, REMOVE_COMPANY, CREATE_COMPANY, getCompanies, UPDATE_COMPANY, getCategories, AI_RESUME_FEEDBACK} from '../graphqlClient/graphql'
import { useMutation } from '@apollo/client';
import { serverConfig } from '../api/apiConfig';
import Notifications from './../components/notifications/Notifications';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  listContainer: {
    //maxWidth:'80%',
    width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      justifyContent: 'center',
      alignItems: 'flex-start',
  
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'green',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  addButton: {
    margin: theme.spacing(2),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

const AiInterviewSimulation: React.FC<PropsFromRedux> = ({ isLoadingUsuarios, isLoadingUsuariosByFilter, isLoadingCreateUsuario, empresas }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const messages:any = []
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedUsuario, setSelectedUsuario] = React.useState('');
 
  const [loading, setLoading] = React.useState(false);

  
  const [categories, setCategories] = React.useState<any>([]);
  const [companies, setCompanies] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>({});
  const [selectedCompany, setSelectedCompany] = React.useState<any>({});
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [save, setSave] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<any>({ key: 0, value: 'Filtro por operador' });

  const [messageData, setMessageData] = React.useState<any>('');
  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  const [expanded, setExpanded] = React.useState('');
  const [link, setLink] = React.useState('');
  const [AiResumeFeedback, { data }] = useMutation(AI_RESUME_FEEDBACK);
  const getCategoriesAsync=async ()=>{
    
    const result:any = await getCategories()
    console.log('resutl ===>',result)
    
    if(result?.length){
      setCategories(result)
    }
  }

  const getCompaniesAsync=async ()=>{

    const result:any = await getCompanies()
    console.log('resutl ===>',result)
    
    if(result?.length){
      setCompanies(result)
    }
  }




  useEffect(() => {
   
    getCategoriesAsync();
    getCompaniesAsync();

  }, []);



  
  useEffect(() => {
    if (isLoadingUsuarios) {
      setLoading(true)
    }
    if (loading && !isLoadingUsuarios) {
      
      setLoading(false)
    }
    console.log('isLoadingUsuarios ==> ', isLoadingUsuarios)

  }, [isLoadingUsuarios]);

  useEffect(() => {
    if (isLoadingCreateUsuario) {
      setLoading(true)
    }
    if (loading && !isLoadingCreateUsuario) {
      
      setLoading(false)
    }
    console.log('isLoadingCreateUsuario ==> ', isLoadingCreateUsuario)

  }, [isLoadingCreateUsuario]);

  const handleDeleteClick = (UsuarioId: any) => {
    console.log('UsuarioId', UsuarioId)
    setSelectedUsuario(UsuarioId);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmation = () => {
    console.log('selectedUsuario', selectedUsuario)
    //deleteUsuario(selectedUsuario);
    setDeleteConfirmationOpen(false);
  };






  //init DataGrid dependencies

  const clearData = (d: any) => {
    const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
    return dateTransform
  }

 
 



 






  const sendMessage = async ()=>{
    console.log(messageData)
    const createChatgptInput:any = {
      
    }
    /* if(file){
      console.log(file)
      createChatgptInput.pdf = file;
      //createChatgptInput.contador = (contador+1);
    }  */
    // createChatgptInput.id = createChatgptInput._id
    delete createChatgptInput.__typename
    console.log('createChatgptInput',createChatgptInput)
    if(typeof createChatgptInput.image == 'string') delete createChatgptInput.image
    try{
      const userToken = await AiResumeFeedback({
        variables: {
          createChatgptInput: createChatgptInput,
          
        },
        onCompleted: ({updateUser} ) => {
          
          console.log('user result== > ',updateUser);
          const notData = {
            type : 'success',
            message : 'user updating success',
            title : 'wescout',
            timeOut : 2500,
            callback : ()=>{},
            priority : true,
          }
          Notifications(notData)
          
        }
      });
     } catch(error:any){
      const notData = {
        type : 'error',
        message : error.message,
        title : 'wescout',
        timeOut : 2500,
        callback : ()=>{},
        priority : true,
      }
      Notifications(notData)
      console.log("Erro ao atualizar usuário", error)
  
     }
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      /* setFile(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0])) */
    }
  };



  return (
    <Container className={classes.container}>
      <div className='root'>
        <div className={classes.content}>
          {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}
          <Typography variant="h4" className={'title'}>
          AI Interview Simulation
          </Typography>
          {loading ? (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                ) : null}
          <Box sx={{ width: '100%', height: 100, }} style={{ alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>
            <Stack borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
                <div id="chatbox" className='chatbox'>
                    <div id="messages"></div>
                    <TextField
                        style={{marginBottom:5}}
                        label="type your message here"
                        value={messageData}
                        onChange={(e) =>{
                            setMessageData(e.target.value)
                            messages.push(e.target.value)}}
                    />
                </div>
                <Button className={classes.addButton} variant="contained" color="primary"  onClick={() => sendMessage()}>
                    Send
                </Button>

           







            </Stack>


          </Box>


          

          
          <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
        </div>
      </div>

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirm </DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you sure you want to delete?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      
    </Container>
  );
};

const mapStateToProps = (state: any) => ({


  isLoadingUsuarios: state.usuarios.isLoadingUsuarios,
  isLoadingUsuariosByFilter: state.usuarios.isLoadingUsuariosByFilter,
  isLoadingCreateUsuario: state.usuarios.isLoadingCreateUsuario,
  empresas: state.empresas.empresas
});

const mapDispatchToProps = {

};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AiInterviewSimulation);
