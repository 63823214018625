import * as api from '../../api';
import RfidApi from '../../api/rfidApi';
import {serverConfig} from '../../api/apiConfig';
import * as base64 from 'base-64';
// Defina o tipo de ação
//
export const GET_NOTIFICACOES_SUCCESS = 'GET_NOTIFICACOES_SUCCESS';
export const GET_NOTIFICACAO_BY_ID_SUCCESS = 'GET_NOTIFICACAO_BY_ID_SUCCESS';
export const CREATE_NOTIFICACAO_SUCCESS = 'CREATE_NOTIFICACAO_SUCCESS';
export const CREATE_NOTIFICACAO = 'CREATE_NOTIFICACAO';
export const DELETE_NOTIFICACAO_SUCCESS = 'DELETE_NOTIFICACAO_SUCCESS';
export const DELETE_NOTIFICACAO = 'DELETE_NOTIFICACAO';
export const UPDATE_NOTIFICACAO_SUCCESS = 'UPDATE_NOTIFICACAO_SUCCESS';
export const UPDATE_NOTIFICACAO_START = 'UPDATE_NOTIFICACAO_START';
export const GET_TOKENS_SUCCESS = 'GET_TOKENS_SUCCESS';
export const GET_TOKENS_START = 'GET_TOKENS_START';




export const updateToken = async (renewToken: any)=> {
  const rfidApi = new RfidApi(renewToken);
      const response:any = await rfidApi.Fetch({
        method: 'post',
        url: `${serverConfig.pathUseCases.auth.authRefresh}`
      });

      if(response.data){
        localStorage.setItem('AUTH_USER_TOKEN_KEY', response.data.token)		  
    	  localStorage.setItem('AUTH_USER_RENEW_TOKEN_KEY', response.data.renewToken)		  
        return response.data.token
      }

}

export function* verifyToken (token: any){
  // Decodifica o token em arquivo json
  const decodeToken=()=> {
    const myToken = token
    if(! myToken ) {
      return null;
    }
    console.log('test token ===>>>', (base64.decode(myToken.split('.')[1])).toString())
    return (base64.decode(myToken.split('.')[1])).toString();
  }

  const isExpired =(): boolean => {
    let tokenProps:any = decodeToken();
    if(typeof tokenProps == 'string')
      tokenProps = JSON.parse(tokenProps)
    console.log('myToken', tokenProps  )
    if(! tokenProps ) {
      return true;
    }
    const dtExpire = 1000 * tokenProps['exp'];
    console.log('dtExpire', tokenProps['exp'],dtExpire, + new Date());
    return ( (+ new Date() )> dtExpire ); 
  }

  const condicao = isExpired()

  return condicao

}



export const getNotificacoes = () => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getNotificacoes token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'get',
    body: {},
    url: serverConfig.pathUseCases.notificacoes.getNotificacoes,
  });
  console.log('response ==> ',response.data)
  dispatch({ type: GET_NOTIFICACOES_SUCCESS, payload: response.data.data });
}
export const getTokens = () => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('gettokens',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'get',
    body: {},
    url: serverConfig.pathUseCases.notificacoes.getTokens,
  });
  console.log('response gettokens ==> ',response.data)
  dispatch({ type: GET_TOKENS_SUCCESS, payload: response.data.data });
}

export const deleteNotificacao = (notificacaoId:any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  dispatch({ type: DELETE_NOTIFICACAO, payload: null });
  console.log('deleteNotificacao token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  
    const rfidApi = new RfidApi(token);


    const response: any = await rfidApi.Fetch({
        method: 'delete',
        body: {},
        url: `${serverConfig.pathUseCases.notificacoes.deleteNotificacao(notificacaoId)}`,
      });
  //const produtos = await api.getprodutos();
  console.log('response ==> ',response.data)
  dispatch({ type: DELETE_NOTIFICACAO_SUCCESS, payload: response.data.data });
}

export const getNotificacaoById = (id: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  //const notificacao = await api.getNotificacaoById(id);
  //dispatch({ type: GET_NOTIFICACAO_BY_ID_SUCCESS, payload: notificacao });
}

export const createNotificacao = (notificacao: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({ type: CREATE_NOTIFICACAO, payload: null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getNotificacoes token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  console.log('notificacao actions',notificacao)
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'post',
    body: notificacao,
    url: serverConfig.pathUseCases.notificacoes.createNotificacao,
  });
  console.log('response create notificacao ==> ',response)
  dispatch({ type: CREATE_NOTIFICACAO_SUCCESS, payload: response.data.data });
  
}
export const updateNotificacao = (notificacao: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: UPDATE_NOTIFICACAO_START, payload : null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getNotificacoes token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  console.log('notificacao',notificacao)
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'patch',
    body: notificacao,
    url: `${serverConfig.pathUseCases.notificacoes.updateNotificacao(notificacao._id)}`,
    
  });
  console.log('response edit notificacao ==> ',response)
  getNotificacoes()
  dispatch({ type: UPDATE_NOTIFICACAO_SUCCESS, payload : null });
}



// E assim por diante para as outras ações...
