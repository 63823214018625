// Importe as constantes de ação


import {
    GET_PRODUTOS,
    GET_PRODUTOS_SUCCESS,
    GET_PRODUTO_BY_ID_SUCCESS,
    GET_PRODUTOS_BY_CATEGORY,
    GET_PRODUTOS_BY_CATEGORY_SUCCESS,
    UPDATE_PRODUTO,
    UPDATE_PRODUTO_SUCCESS,
    UPDATE_PRODUTOS_FAILED,
    UPDATE_PRODUTOS,
    UPDATE_PRODUTOS_SUCCESS,
    UPDATE_PRODUTO_FAILED,
    GET_LOGS,
    GET_LOGS_SUCCESS,
    SET_PRODUTO_ID,
    SET_PRODUTO,
    SET_LOG_SELECIONADO,
    DELETE_PRODUTO,
    DELETE_PRODUTO_SUCCESS,
    GET_IMAGES_BY_PRODUCT,
    GET_IMAGES_BY_PRODUCT_SUCCESS,
    GET_IMAGE,
    GET_IMAGE_SUCCESS,
    GET_INVENTORIES_BY_CATEGORY_START,
    GET_INVENTORIES_BY_CATEGORY_FAILURE,
    GET_INVENTORIES_BY_CATEGORY_SUCCESS,
    GET_INVENTORIES_BY_FILTER_START,
    GET_INVENTORIES_BY_FILTER_FAILURE,
    GET_INVENTORIES_BY_FILTER_SUCCESS,
    GET_LOGS_BY_FILTER_START,
    GET_LOGS_BY_FILTER_FAILURE,
    GET_LOGS_BY_FILTER_SUCCESS,

    GET_PRODUTOS_BY_FILTER,
    GET_PRODUTOS_BY_FILTER_SUCCESS,
    GET_PRODUTOS_BY_FILTER_FAILURE,

    GET_PRODUTOS_COUNT,
    GET_PRODUTOS_COUNT_SUCCESS,
    GET_PRODUTOS_COUNT_FAILURE,

    GET_INVENTARIOS_COUNT,
    GET_INVENTARIOS_COUNT_SUCCESS,
    GET_INVENTARIOS_COUNT_FAILURE,

    GET_ALL_INVENTORY_FAILURE,
    GET_ALL_INVENTORY_START,
    GET_ALL_INVENTORY_SUCCESS,
    CONSULT_PRODUCT_FAILURE,
    CONSULT_PRODUCT_SUCCESS,

    MOVPROD_CONSULTAR_TIPO,
    MOVPROD_CONSULTAR_TIPO_FAILURE,
    MOVPROD_CONSULTAR_TIPO_SUCCESS,
    
  } from './actions';
  
  // Estado inicial
  const initialState = {
    isLoadingProdutos : false,
    isLoadingProdutosUpdate : false,
    isLoadingProdutosUpdateMany : false,
    isLoadingProdutosDelete : false,
    isLoadingInventory : false,
    isLoadingLogs : false,
    isLoadingImages: false,
    logs : [],
    logSelecionado : {},
    produtoId : '',
    produto : '',
    produtosCountByCategory : [],
    inventariosCountByProduto : [],
    produtos: [], // Valor inicial do produtos
    consultedProduts: {},
    imagesByProduct:[],
    selectedImage:'',
    inventories:[],
    inventory:{},
    errorMessage:'',
    isLoadingCount:false,
    movprodTipo : []
  }
  
  export default function produtosReducer(state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
      case GET_PRODUTOS:
        return { ...state, isLoadingProdutos: true };
      case GET_PRODUTOS_SUCCESS:
        return { ...state, isLoadingProdutos:false,  produtos: action.payload };
      case GET_PRODUTOS_BY_CATEGORY:
        return { ...state, isLoadingProdutos: true };
      case GET_PRODUTOS_BY_CATEGORY_SUCCESS:
        return { ...state, isLoadingProdutos:false,  produtos: action.payload };
      case GET_LOGS:
        return { ...state, isLoadingLogs: true };
      case GET_LOGS_SUCCESS:
        return { ...state, isLoadingLogs:false,  logs: action.payload };
      case GET_IMAGE:
        return { ...state, isLoadingImage: true };
      case GET_IMAGE_SUCCESS:
        return { ...state, isLoadingImage:false,  selectedImage: action.payload };
      case GET_IMAGES_BY_PRODUCT:
        return { ...state, isLoadingImage: true };
      case GET_IMAGES_BY_PRODUCT_SUCCESS:
        return { ...state, isLoadingImage:false,  imagesByProduct: action.payload };
      case UPDATE_PRODUTO:
        return { ...state, isLoadingProdutosUpdate:true };
      case UPDATE_PRODUTO_SUCCESS:
        return { ...state, isLoadingProdutosUpdate:false };
      case UPDATE_PRODUTOS:
        return { ...state, isLoadingProdutosUpdateMany:true };
      case UPDATE_PRODUTOS_SUCCESS:
        return { ...state, isLoadingProdutosUpdateMany:false };
      case DELETE_PRODUTO:
        return { ...state, isLoadingProdutosDelete:true };
      case DELETE_PRODUTO_SUCCESS:
        console.log('DELETE_PRODUTO_SUCCESS')
        return { ...state, isLoadingProdutosDelete:false };
      case SET_PRODUTO_ID:
        return { ...state, produtoId:action.payload };
      case SET_PRODUTO:
        return { ...state, produto:action.payload };
      case SET_LOG_SELECIONADO:
        return { ...state, logSelecionado:action.payload };
      case GET_ALL_INVENTORY_START:
        
        return {
        ...state,  
        isLoadingInventory : true,
      };
      case GET_ALL_INVENTORY_SUCCESS:
        //console.log('GET_ALL_INVENTORY_SUCCESS', action.payload)
        return {
        ...state,  
        inventories : action.payload,
        isLoadingInventory : false,
      };
      case GET_ALL_INVENTORY_FAILURE:
        console.log('GET_ALL_INVENTORY_FAILURE', action.payload)
        return {
        ...state,  
        isLoadingInventory : false,
      };
      case GET_PRODUTOS_COUNT: 
        return {
        ...state,  
        isLoadingCount : true,
      };
      case GET_PRODUTOS_COUNT_SUCCESS:
        //console.log('GET_PRODUTOS_COUNT_SUCCESS', action.payload)
        return {
        ...state,  
        produtosCountByCategory : action.payload,
        isLoadingCount : false,
      };
      case GET_PRODUTOS_COUNT_FAILURE:
        //console.log('GET_PRODUTOS_COUNT_FAILURE', action.payload)
        return {
        ...state,  
        isLoadingCount : false,
      };
      case MOVPROD_CONSULTAR_TIPO: 
        return {
        ...state,  
        isLoadingCount : true,
      };
      case MOVPROD_CONSULTAR_TIPO_SUCCESS:
        //console.log('MOVPROD_CONSULTAR_TIPO_SUCCESS', action.payload)
        return {
        ...state,  
        movprodTipo : action.payload,
        isLoadingCount : false,
      };
      case MOVPROD_CONSULTAR_TIPO_FAILURE:
        //console.log('MOVPROD_CONSULTAR_TIPO_FAILURE', action.payload)
        return {
        ...state,  
        isLoadingCount : false,
      };
      case GET_INVENTARIOS_COUNT:
        
        return {
        ...state,  
        isLoadingCount : true,
      };
      case GET_INVENTARIOS_COUNT_SUCCESS:
        //console.log('GET_INVENTARIOS_COUNT_SUCCESS', action.payload)
        return {
        ...state,  
        inventariosCountByProduto : action.payload,
        isLoadingCount : false,
      };
      case GET_INVENTARIOS_COUNT_FAILURE:
        //console.log('GET_INVENTARIOS_COUNT_FAILURE', action.payload)
        return {
        ...state,  
        isLoadingCount : false,
      };
      case GET_INVENTORIES_BY_CATEGORY_START:
        
        return {
        ...state,  
        isLoadingInventory : true,
      };
      case GET_INVENTORIES_BY_CATEGORY_SUCCESS:
        //console.log('GET_INVENTORY_SUCCESS', action.payload)
        return {
        ...state,  
        inventories : action.payload,
        isLoadingInventory : false,
      };
      case GET_INVENTORIES_BY_CATEGORY_FAILURE:
        //console.log('GET_ALL_INVENTORY_FAILURE', action.payload)
        return {
        ...state,  
        isLoadingInventory : false,
      };
      case GET_INVENTORIES_BY_FILTER_START:
        
        return {
        ...state,  
        isLoadingInventory : true,
      };
      case GET_INVENTORIES_BY_FILTER_SUCCESS:
        //console.log('GET_INVENTORIES_BY_FILTER_SUCCESS', action.payload)
        return {
        ...state,  
        inventories : action.payload,
        isLoadingInventory : false,
      };
      case GET_INVENTORIES_BY_FILTER_FAILURE:
        //console.log('GET_INVENTORIES_BY_FILTER_FAILURE', action.payload)
        return {
        ...state,  
        errorMessage : action.payload,
        isLoadingInventory : false,
      };
      case GET_LOGS_BY_FILTER_START:
        
        return {
        ...state,  
        isLoadingLogs : true,
      };
      case GET_LOGS_BY_FILTER_SUCCESS:
        //console.log('GET_LOGS_BY_FILTER_SUCCESS', action.payload)
        return {
        ...state,  
        logs : action.payload,
        isLoadingLogs : false,
      };
      case GET_LOGS_BY_FILTER_FAILURE:
        //console.log('GET_LOGS_BY_FILTER_FAILURE', action.payload)
        return {
        ...state,  
        errorMessage : action.payload,
        isLoadingLogs : false,
      };
      case GET_PRODUTOS_BY_FILTER:
        
        return {
        ...state,  
        isLoadingProdutos : true,
      };
      case GET_PRODUTOS_BY_FILTER_SUCCESS:
        //console.log('GET_PRODUTOS_BY_FILTER_SUCCESS', action.payload)
        return {
        ...state,  
        produtos : action.payload,
        isLoadingProdutos : false,
      };
      case GET_PRODUTOS_BY_FILTER_FAILURE:
        //console.log('GET_INVENTORIES_BY_FILTER_FAILURE', action.payload)
        return {
        ...state,  
        errorMessage : action.payload,
        isLoadingProdutos : false,
      };
        case CONSULT_PRODUCT_SUCCESS:
        //console.log('CONSULT_PRODUCT_SUCCESS', action.payload)
        const id = action.payload.key
        const newObj:any = {...state.consultedProduts}
        if(action.payload.data){
          newObj[action.payload.key] = action.payload.data
        }
        
      return {
        ...state,
        isLoadingProducts : false,
        consultedProduts : action.payload.data ? newObj : state.consultedProduts
      };
    case CONSULT_PRODUCT_FAILURE:
        //console.log('GET_PRODUCTS_BY_CATEGORY', action.payload)
      return {
        ...state,
        isLoadingProducts : false,
      };
     
      // E assim por diante para os outros casos...
      default:
        return state;
    }
  }