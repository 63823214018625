import { Api } from './api';
//import AsyncStorage from '@react-native-async-storage/async-storage';
import {serverConfig} from './apiConfig';
//import { put,takeLatest } from 'redux-saga/effects';

export default class RfidApi extends Api {
  static *Fetch(arg0: { method: string; url: string; }) {
    throw new Error('Method not implemented.');
  }

  token;
  defaultHeader;
  

  constructor(token: any) {
    super();
    //this.BASE_URL = super.BASE_URL;
    this.token = token;
    this.defaultHeader = {
      Authorization: `Bearer `+ this.token,
      'Content-Type': 'application/json',
    };
    return;
  }
  
}


// Property 'defaultHeader' will overwrite the base property in 'Api'. If this is intentional, add an initializer. Otherwise, add a 'declare' modifier or remove the redundant declaration.

// (property) RfidApi.defaultHeader: any