// Importe as constantes de ação


import {

  FETCH_EMPRESAS_SUCCESS,
  FETCH_EMPRESA_BY_ID_SUCCESS,
  CREATE_EMPRESA_SUCCESS,
  UPDATE_EMPRESA_SUCCESS,
  UPDATE_EMPRESA_START,
  GET_IMAGES_EMPRESA,
  GET_IMAGES_EMPRESA_FAILURE,
  GET_IMAGES_EMPRESA_SUCCESS,
  SET_IMAGE_EMPRESA_PROFILE_SUCCESS
} from './actions';

// Estado inicial
const initialState = {
  empresas: [],
  empresa: null,
  isLoadingEmpresa : false,
  isLoadingEmpresaImages: false,
  imagesByEmpresa : [],
  imageEmpresaProfile : ''
}

export default function empresaReducer(state = initialState, action: { type: any; payload: any; }) {
  switch (action.type) {
    case FETCH_EMPRESAS_SUCCESS:
      console.log('FETCH_EMPRESAS_SUCCESS', action.payload)
      return { ...state, empresas: action.payload };
    case FETCH_EMPRESA_BY_ID_SUCCESS:
      return { ...state, empresa: action.payload };
    case CREATE_EMPRESA_SUCCESS:
      return { ...state, empresas: [...state.empresas, action.payload] };
    case UPDATE_EMPRESA_START:
      console.log('UPDATE_EMPRESA_START')
      return { ...state, isLoadingEmpresa:true};
    case UPDATE_EMPRESA_SUCCESS:
      return { ...state, isLoadingEmpresa:false};
    // E assim por diante para os outros casos...
    case SET_IMAGE_EMPRESA_PROFILE_SUCCESS:
        console.log('set_image_empresa_profile', action.payload)
        return { ...state, imageEmpresaProfile: action.payload };
      case GET_IMAGES_EMPRESA:
        return { ...state, isLoadingEmpresaImages: true };
      case GET_IMAGES_EMPRESA_SUCCESS:
        return { ...state, isLoadingEmpresaImages:false,  imagesByEmpresa: action.payload };
    default:
      return state;
  }
}

  