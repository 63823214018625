
import * as api from '../../api';
import RfidApi from '../../api/rfidApi';
import {serverConfig} from '../../api/apiConfig';
import * as base64 from 'base-64';
import axios from 'axios';
// Defina o tipo de ação
//
export const FETCH_REFERENCIAS_SUCCESS = 'FETCH_REFERENCIAS_SUCCESS';
export const FETCH_REFERENCIA_BY_ID = 'FETCH_REFERENCIA_BY_ID';
export const FETCH_REFERENCIA_BY_ID_SUCCESS = 'FETCH_REFERENCIA_BY_ID_SUCCESS';
export const CREATE_REFERENCIA_SUCCESS = 'CREATE_REFERENCIA_SUCCESS';
export const UPDATE_REFERENCIA_SUCCESS = 'UPDATE_REFERENCIA_SUCCESS';
export const UPDATE_REFERENCIA_START = 'UPDATE_REFERENCIA_START';
export const DELETE_REFERENCIA = 'DELETE_REFERENCIA';
export const DELETE_REFERENCIA_SUCCESS = 'DELETE_REFERENCIA_SUCCESS';
export const GET_IMAGES_REFERENCIA = 'GET_IMAGES_REFERENCIA';
export const GET_IMAGES_REFERENCIA_SUCCESS = 'GET_IMAGES_REFERENCIA_SUCCESS';
export const GET_IMAGES_REFERENCIA_FAILURE = 'GET_IMAGES_REFERENCIA_FAILURE';
export const SET_IMAGE_REFERENCIA_PROFILE_SUCCESS = 'SET_IMAGE_REFERENCIA_PROFILE_SUCCESS';


export const updateToken = async (renewToken: any)=> {
  const rfidApi = new RfidApi(renewToken);
      const response:any = await rfidApi.Fetch({
        method: 'post',
        url: `${serverConfig.pathUseCases.auth.authRefresh}`
      });

      if(response.data){
        localStorage.setItem('AUTH_USER_TOKEN_KEY', response.data.token)		  
    	  localStorage.setItem('AUTH_USER_RENEW_TOKEN_KEY', response.data.renewToken)		  
        return response.data.token
      }

}

export function* verifyToken (token: any){
  // Decodifica o token em arquivo json
  const decodeToken=()=> {
    const myToken = token
    if(! myToken ) {
      return null;
    }
    console.log('test token ===>>>', (base64.decode(myToken.split('.')[1])).toString())
    return (base64.decode(myToken.split('.')[1])).toString();
  }

  const isExpired =(): boolean => {
    let tokenProps:any = decodeToken();
    if(typeof tokenProps == 'string')
      tokenProps = JSON.parse(tokenProps)
    console.log('myToken', tokenProps  )
    if(! tokenProps ) {
      return true;
    }
    const dtExpire = 1000 * tokenProps['exp'];
    console.log('dtExpire', tokenProps['exp'],dtExpire, + new Date());
    return ( (+ new Date() )> dtExpire ); 
  }

  const condicao = isExpired()

  return condicao

}

export const setImageReferenciaProfile = (image: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  //const produto = await api.getProdutoById(id);
  dispatch({ type: SET_IMAGE_REFERENCIA_PROFILE_SUCCESS, payload : image });
}


export const getImagesByReferencia = (payload: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  try {
    dispatch({ type: GET_IMAGES_REFERENCIA, payload: null });
    let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
    const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
    
    console.log('token getUserAsync',token)
    let vt = verifyToken(token);
    if(vt){
      token = await updateToken(renewToken)
      console.log('refreshToken ', token)
    }

    const params:any = {referencia : 'referencia' , idReferencia: payload}

    const rfidApi = new RfidApi(token);
    const response:any = await rfidApi.Fetch({
      method: 'post',
      body: params,
      url: `${serverConfig.pathUseCases.referencias.getImages}`,
    });

    console.log('getImagesByProductStart  =====>>>>>>    ', response.data)
    dispatch({ type: GET_IMAGES_REFERENCIA_SUCCESS, payload: response.data.data });
    //await put(getImagesByProductSuccess(response.data.data));

  } catch (error) {
    dispatch({ type: GET_IMAGES_REFERENCIA_FAILURE, payload: error });
  }
}



export const getReferencias = () => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('fetchReferencias token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'get',
    body: {},
    url: serverConfig.pathUseCases.referencias.getReferencias,
  });
  console.log('response referencias ==> ',response.data)
  dispatch({ type: FETCH_REFERENCIAS_SUCCESS, payload: response.data.data });
}

export const deleteReferencia = (referenciaId:any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  
  dispatch({ type: DELETE_REFERENCIA, payload: null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');

  console.log('deleteReferencia token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  
    const rfidApi = new RfidApi(token);


    const response: any = await rfidApi.Fetch({
      method: 'delete',
      body: {},
      url: serverConfig.pathUseCases.referencias.deleteReferencia(referenciaId),
    });
  
  console.log('response delete ==> ',response.data)
  dispatch({ type: DELETE_REFERENCIA_SUCCESS, payload: response.data.data });
}

export const getReferenciaById = (id: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: FETCH_REFERENCIA_BY_ID, payload: null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('fetchReferencias token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'get',
    url: serverConfig.pathUseCases.referencias.getReferenciaById(id),
  });
  console.log('response get referencia ==> ',response)
  dispatch({ type: FETCH_REFERENCIA_BY_ID_SUCCESS, payload: response.data.data });
}



export const createReferencia = (referencia: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  let hasFile = false
  let file = false
  let dataToSend:any = {}
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('fetchReferencias token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  if(referencia.file){
    hasFile = true
    console.log('has file *****************')
    file = referencia.file;
    dataToSend = 
      {
        "referencia": "referencia",
        "idReferencia": referencia._id,
        "titulo": "imagem"+referencia.contador||1,
        "descricao": "profile_referencia",
        "contador": referencia.contador||1
      }

      delete referencia.contador
      //console.log('has file *****************', dataToSend )
      //console.log('has file *****************', referencia.file )
      
      
    delete referencia.file
  }
  console.log('referencia',referencia)
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'post',
    body: referencia,
    url: serverConfig.pathUseCases.referencias.createReferencia,
  });
  if (hasFile&&response.data.data) {
    dataToSend.idReferencia = response.data.data._id
    try{
      const sendFile = await uploadImage(dataToSend, file, token);
  } catch(err){
    console.log('erro sendfile ===>>> ',err)

  }

  }
  console.log('response create referencia ==> ',response)
  dispatch({ type: CREATE_REFERENCIA_SUCCESS, payload: response.data.data });
}


export const uploadImage = async(data: any, file:any, token:any)=> {
  var axiosResponse
  const fileData = data;
  console.log('fileData init ===>>>>', fileData)
  console.log('fileData init ===>>>>', token)
    const config = {
        headers: {
          //Accept: '*/*',
          'Authorization':'Bearer ' + token,
          'Content-Type': 'multipart/form-data; charset=utf-8; boundary="another cool boundary";',
        }
    };
      const form = new FormData();
      //form.append('data', JSON.stringify(data));
       Object.keys(fileData).forEach((key) => {
        console.log(key)
          form.append(key, fileData[key]);
      }); 
    
    
      if(file && !file.type && file.uri){
        const list = file.uri.split('.')
        const len = list.length
        file['type'] = 'image/'+list[len-1]
      }
      if(!file.name)
        file['name'] = file.fileName;

      console.log("file.type",file)
      if(file && file.type){
        form.append("arquivo", file);
        //form.append('Content-Type',file.type)
        console.log('form 1 ====>> ',form)
        
          let endPoint = `${serverConfig.dev.url}${serverConfig.pathUseCases.produtos.uploadImage}`
          console.log('endPoint',endPoint)
          console.log('config',config)
          try {
            axiosResponse = await axios.request({
              url: endPoint,
              method: 'post',
              data: form,
              headers: config.headers
              // withCredentials: true,
            });
            
            
          } catch (error) {
            axiosResponse = error;
            console.log('error api : ', axiosResponse);
          }
          console.log('api response upload : ', axiosResponse);
      }
      return axiosResponse;
}




export const updateReferencia = (referencia: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: UPDATE_REFERENCIA_START, payload : null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('fetchReferencias token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  console.log('referencia',referencia)
  let hasFile = false;

  if(referencia.file){
    hasFile = true
    console.log('has file *****************')
    const dataToSend = 
      {
        "referencia": "referencia",
        "idReferencia": referencia._id,
        "titulo": "imagem"+referencia.contador||1,
        "descricao": "profile_referencia",
        "contador": referencia.contador||1
      }

      delete referencia.contador
      //console.log('has file *****************', dataToSend )
      //console.log('has file *****************', referencia.file )
      try{
          const sendFile = await uploadImage(dataToSend, referencia.file, token);
      } catch(err){
        console.log('erro sendfile ===>>> ',err)

      }
      
    delete referencia.file
  }


  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'patch',
    body: referencia,
    url: `${serverConfig.pathUseCases.referencias.updateReferencia(referencia._id)}`,
    
  });
  console.log('response edit referencia ==> ',response)
  //getReferencias()
  dispatch({ type: UPDATE_REFERENCIA_SUCCESS, payload : null });
}



// E assim por diante para as outras ações...
